import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Service from 'services/mailSystem.service'
import { CreateButton, OpenButton } from '../../components/Button/Buttons'
import { useToastContext } from '../../context/ToastContext'
import {
  ActionIconWrapper,
  ComponentWrapper,
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
} from '../Table/Table'
import { TableBody } from '../Table/TableBody'
import { TableHead } from '../Table/TableHead'

const MailSystems = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const [data, setData] = useState([])

  const entityGroupName = 'mailsystems'
  const entityName = 'Mail System'
  const notFound = `Kein ${entityName} gefunden`

  function errorMessage(error) {
    return `Technischer Fehler: ${error}`
  }

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    try {
      const result = await Service.get()
      setData(result)
    } catch (error) {
      addToast(errorMessage(error), 'error')
    }
  }

  return (
    <>
      <ComponentWrapper>
        <div className="text-center">
          <CreateButton
            onClick={() => navigate(`/${entityGroupName}/create`)}
            title={`${entityName} anlegen`}
          />
        </div>
      </ComponentWrapper>
      <ComponentWrapper>
        <Table>
          <TableHead>
            <TableHeaderRow>
              <TableCell>Name</TableCell>
              <TableCell>API Url</TableCell>
              <TableCell></TableCell>
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow index={0}>
                <TableCell colSpan={3} className="text-center py-4">
                  {notFound}
                </TableCell>
              </TableRow>
            ) : (
              data.map((item, index) => (
                <TableRow index={index} key={item._id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.apiUrl}</TableCell>
                  <TableCell>
                    <ActionIconWrapper>
                      <OpenButton
                        onClick={() => navigate(`/${entityGroupName}/${item._id}/update`)}
                      />
                    </ActionIconWrapper>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </ComponentWrapper>
    </>
  )
}

export default MailSystems
