import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CommonService from 'services/common.service'
import Service from 'services/mailSystem.service'
import { useToastContext } from '../../context/ToastContext'
import DetailFooter from '../Common/DetailFooter'
import { InputText } from '../Common/Inputs'

const entityGroupName = 'mailsystems'
const entityName = 'Mail System'

export const MailSystemForm = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const { objectId } = useParams()
  const [pageState, setPageState] = useState({
    data: Service.initializeObject,
    editMode: false,
    createMode: typeof objectId === 'undefined',
  })

  useEffect(() => {
    if (!pageState.createMode) {
      getData()
    }
  }, [])

  async function getData() {
    Service.getByObjectId(objectId)
      .then((result) => {
        setPageState((prevState) => ({ ...prevState, data: result }))
      })
      .catch((error) => {
        addToast(CommonService.errorMessage(error), 'error')
      })
  }

  const onChange = (event) => {
    CommonService.onHandleChange((newData) => {
      setPageState((prevState) => ({
        ...prevState,
        data: newData,
      }))
    }, event)
  }

  async function handleOnSubmit() {
    if (pageState.createMode) {
      await Service.create(pageState.data)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, pageState.createMode), 'success')
          navigate(`/${entityGroupName}/${result._id}/update`)
        })
        .catch((error) => {
          addToast(CommonService.errorMessage(error), 'error')
        })
    } else {
      await Service.update(pageState.data)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, pageState.createMode), 'success')
          setPageState((prevState) => ({ ...prevState, editMode: false }))
        })
        .catch((error) => {
          addToast(CommonService.errorMessage(error), 'error')
        })
        .finally(() => {
          getData()
        })
    }
  }

  const { data, editMode, createMode } = pageState
  const disabled = !editMode && !createMode

  return (
    <>
      <div className="flex gap-2">
        <div className="flex-1">
          <InputText
            name="name"
            title="Name"
            value={data.name}
            onChange={onChange}
            required={true}
            disabled={disabled}
          />
        </div>
        <div className="flex-1">
          <InputText
            name="apiUrl"
            title="API Url"
            value={data.apiUrl}
            onChange={onChange}
            required={true}
            disabled={disabled}
          />
        </div>
      </div>
      <DetailFooter
        data={data}
        showSaveButton={editMode || createMode}
        saveButtonDisabled={!data.name}
        showDatabaseInfo={false}
        handleOnSubmit={handleOnSubmit}
        handleOnEditClicked={() => setPageState((prevState) => ({ ...prevState, editMode: true }))}
        handleOnCancelClicked={() =>
          setPageState((prevState) => ({ ...prevState, editMode: false }))
        }
      />
    </>
  )
}
