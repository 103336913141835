import axios from 'axios'
import { authenticationService } from '../services/authentication.service'

const apiBaseUrl = process.env.REACT_APP_API_ADDRESS
console.log('API-ADDRESS: ' + apiBaseUrl)

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
})

axiosInstance.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    if ([401, 403].indexOf(error.response.status) !== -1) {
      authenticationService.logout()
      window.location.reload(true)
    }

    return Promise.reject(error.message)
  }
)

export default axiosInstance
