import { CreateButton, DeleteButton, OpenButton } from 'components/Button/Buttons'
import {
  ActionIconWrapper,
  ComponentWrapper,
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table/Table'
import { TableBody } from 'components/Table/TableBody'
import { TableHead } from 'components/Table/TableHead'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteCustomer, getCustomerList } from 'services/customer.service'
import { useToastContext } from '../../context/ToastContext'
import SimpleModal from '../Common/SimpleModal'

const Index = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [modal, setModal] = useState({ show: false })

  const entityGroupName = 'customers'
  const entityName = 'Kunde'
  const notFound = `Kein ${entityName} gefunden`

  function errorMessage(error) {
    return `Technischer Fehler: ${error}`
  }
  function deleteSuccessMessage(objectId) {
    return `Der Kunde wurde mit der ID ${objectId} wurde gelöscht.`
  }

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    await getCustomerList()
      .then((result) => {
        setData(result)
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
      })
      .finally(() => {})
  }

  async function handleDelete(objectId) {
    await deleteCustomer(objectId)
      .then((response) => {
        addToast(deleteSuccessMessage(objectId), 'success')
        setModal({ show: false })
        fetchData()
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
      })
      .finally(() => {})
  }

  const openDeleteModal = (objectId, name) => {
    setModal({
      show: true,
      title: `Lösche ${entityName}`,
      text: `Willst du "${name}" wirklich löschen?`,
      onCancelClicked: () => setModal({ show: false }),
      onOkayClicked: () => handleDelete(objectId),
      submitButton: 'Ja',
      backButton: 'Nein',
    })
  }

  return (
    <>
      <ComponentWrapper>
        <div className="text-center">
          <CreateButton
            onClick={() => navigate(`/${entityGroupName}/create`)}
            title={`${entityName} anlegen`}
          />
        </div>
      </ComponentWrapper>
      <ComponentWrapper>
        <Table>
          <TableHead>
            <TableHeaderRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Aktionen</TableCell>
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {typeof data === 'undefined' || data === null || data.length <= 0 ? (
              <tr>
                <td colSpan={3} className="text-center">
                  {notFound}
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <TableRow index={index} key={index}>
                  <TableCell>{item.customerNumber}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell className="flex">
                    <ActionIconWrapper>
                      <OpenButton
                        onClick={() => navigate(`/${entityGroupName}/${item._id}/update`)}
                      />
                      <DeleteButton
                        onClick={() =>
                          openDeleteModal(item._id, `${item.name} (${item.customerNumber})`)
                        }
                      />
                    </ActionIconWrapper>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </ComponentWrapper>
      {modal.show && <SimpleModal modal={modal} />}
    </>
  )
}

export default Index
