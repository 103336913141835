import cx from 'classnames'

export const Table = ({ children, className = undefined }) => {
  const classNames = cx('w-full border-collapse table-auto text-sm', className)
  return (
    <div className="overflow-x-auto my-1 ">
      <table className={classNames}>{children}</table>
    </div>
  )
}

export const ComponentWrapper = ({ children }) => {
  return <div className="border rounded border-blue-200 p-2 mb-2">{children}</div>
}

export const ActionIconWrapper = ({ children }) => {
  return <div className="w-full flex gap-1 items-center justify-end">{children}</div>
}

export const TableHeaderCell = ({ children = undefined, className = undefined, colSpan = 1 }) => {
  const classNames = cx('px-1 py-2 font-bold text-start', className)
  return (
    <td className={classNames} colSpan={colSpan}>
      {children}
    </td>
  )
}

export const TableCell = ({ children = undefined, className = undefined, colSpan = 1 }) => {
  const classNames = cx('px-1 py-2', className)
  return (
    <td className={classNames} colSpan={colSpan}>
      {children}
    </td>
  )
}

export const TableHeaderRow = ({ children }) => {
  return <tr className="font-bold bg-gray-700 text-white text-left">{children}</tr>
}

export const TableRow = ({ children, index, className = undefined, onClick = undefined }) => {
  // Combine default classes with any additional classes provided using cx
  const classNames = cx(
    'border-b border-gray-200 transition-colors duration-300 hover:bg-gray-100',
    index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
    onClick ? 'cursor-pointer' : '',
    className
  )

  return (
    <tr className={classNames} onClick={onClick}>
      {children}
    </tr>
  )
}
