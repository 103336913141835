import { authenticationService } from '../services/authentication.service'

export default function authHeader() {
  // return authorization header with jwt token
  const currentUser = authenticationService.getCurrentUser()
  if (currentUser && currentUser.accessToken) {
    return { 'x-access-token': `${currentUser.accessToken}` }
  } else {
    return {}
  }
}
