import {
  ArrowLeftIcon,
  BackspaceIcon,
  DocumentCheckIcon,
  DocumentDuplicateIcon,
  FolderOpenIcon,
  PencilIcon,
  PlayIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { authenticationService } from 'services/authentication.service'

export function CopyButton({ onClick }) {
  return (
    <DocumentDuplicateIcon
      className="w-8 h-8 cursor-pointer border-2 rounded-md p-1 hover:bg-gray-300 "
      onClick={onClick}
      title="Kopie Erstellen"
    />
  )
}

export function DeleteButton({ onClick }) {
  if (authenticationService.isAdmin) {
    return (
      <TrashIcon
        className="w-8 h-8 cursor-pointer border-2 rounded-md p-1 text-red-500 hover:bg-gray-300"
        onClick={onClick}
        title="Löschen"
      />
    )
  } else {
    return null
  }
}

export function EditButton({ onClick }) {
  return (
    <PencilIcon
      className="w-8 h-8 cursor-pointer border-2 rounded-md p-1 hover:bg-gray-300"
      onClick={onClick}
      title="Bearbeiten"
    />
  )
}

export const SaveButton = ({ enableSaveButton, handleOnSubmit }) => {
  return (
    <DocumentCheckIcon
      className={`w-8 h-8 cursor-pointer border-2 rounded-md p-1 ${
        enableSaveButton() ? 'hover:bg-gray-300' : 'cursor-not-allowed'
      }`}
      onClick={handleOnSubmit}
      title={'Speichern'}
    />
  )
}

export const BackButton = () => {
  const navigate = useNavigate()
  return (
    <ArrowLeftIcon
      className="w-8 h-8 cursor-pointer border-2 rounded-md p-1 hover:bg-gray-300"
      onClick={() => navigate(-1)}
      title={'Zurück'}
    />
  )
}

export const CancelButton = ({ onClick }) => {
  return (
    <BackspaceIcon
      className="w-8 h-8 cursor-pointer border-2 rounded-md p-1 hover:text-red-700"
      onClick={onClick}
      title="Abbrechen"
    />
  )
}

export const TestButton = ({ onClick, title }) => {
  return (
    <PlayIcon
      className="w-8 h-8 cursor-pointer border-2 rounded-md p-1 hover:bg-gray-300"
      onClick={onClick}
      title="Daten abholen"
    >
      {title}
    </PlayIcon>
  )
}

export const CreateButton = ({ onClick, title }) => {
  return (
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-sm text-sm"
      onClick={onClick}
    >
      {title}
    </button>
  )
}

export const OpenButton = ({ onClick }) => {
  return (
    <FolderOpenIcon
      className="w-8 h-8 cursor-pointer border-2 rounded-md p-1 hover:bg-gray-300"
      onClick={onClick}
      title="Öffnen"
    />
  )
}
