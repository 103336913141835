import { faClipboard, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ComponentWrapper,
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table/Table'
import { TableBody } from 'components/Table/TableBody'
import { TableHead } from 'components/Table/TableHead'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AccountService from 'services/account.service'
import ActionService from 'services/action.service'
import CommonService from 'services/common.service'
import ConfigService from 'services/config.service'
import DealService from 'services/deal.service'
import { UserContext } from '../../App'
import { useToastContext } from '../../context/ToastContext'
import MailSystemService from '../../services/mailSystem.service'
import {
  BackButton,
  CancelButton,
  CopyButton,
  DeleteButton,
  EditButton,
  SaveButton,
  TestButton,
} from '../Button/Buttons'
import {
  DropDown,
  InputDate,
  InputNumber,
  InputText,
  InputTextArea,
  InputTime,
} from '../Common/Inputs'
import SimpleModal from '../Common/SimpleModal'

const entityGroupName = `actions`
const entityName = 'Versendung'

const Detail = () => {
  const { userState } = useContext(UserContext)
  const addToast = useToastContext()
  const navigate = useNavigate()
  const { objectId } = useParams()

  const [pageState, setPageState] = useState({
    editMode: false,
    createMode: typeof objectId === 'undefined',
    action: ActionService.initializeObject(),
    dealList: [],
    mailSystemList: [],
    accountList: [],
    statusList: [],
    typeList: ActionService.typeList,
    modal: { show: false },
    actionResult: null,
  })

  const {
    actionResult,
    editMode,
    createMode,
    action,
    dealList,
    accountList,
    mailSystemList,
    typeList,
    statusList,
    modal,
  } = pageState

  const enableSaveButton = () => {
    return (
      action.deal &&
      action.date &&
      action.time &&
      action.useMailSystem &&
      action.mailSystem &&
      action.status &&
      action.type
    )
  }

  async function copyAction() {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Promise.all([ActionService.copyAction(action.deal._id, action._id)])
      .then(([actionsResult]) => {
        navigate(`/actions`)
      })
      .catch((error) => {
        addToast(`Technischer Fehler: ${error}`, 'error')
      })
      .finally(() => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Promise.all([
      DealService.getSimpleDealList(),
      MailSystemService.get(),
      createMode ? ActionService.initializeObject : ActionService.getByObjectId(objectId),
      AccountService.getList(),
      ConfigService.getConfigObject('VersendungStatusListe'),
    ])
      .then(([deals, mailSystems, actionResult, accounts, statusConfig]) => {
        if (statusConfig == null || statusConfig.statusList == null) {
          navigate(`/${entityGroupName}/`)
          addToast('Die Config für die VersendungStatusListe wurde nicht gefunden.', 'error')
        }

        if (typeof mailSystems === 'undefined' || mailSystems === null || mailSystems.length <= 0) {
          navigate(`/${entityGroupName}/`)
          addToast(
            'Es sind keine Versand Systeme angelegt. Es muss mindestens ein Versand System existieren.',
            'error'
          )
        }

        if (typeof deals === 'undefined' || deals == null || deals.length <= 0) {
          navigate(`/${entityGroupName}/`)
          addToast(
            'Es sind keine Kampagnen angelegt. Es muss mindestens eine Kampagne existieren.',
            'error'
          )
        }

        if (createMode) {
          actionResult = action
          actionResult.deal = deals[0]._id
          actionResult.mailSystem = mailSystems[0]._id
          actionResult.useMailSystem = 'Ja'
        }

        setPageState((prev) => ({
          ...prev,
          dealList: deals,
          mailSystemList: mailSystems,
          action: actionResult,
          accountList: accounts,
          statusList: statusConfig.statusList,
          isLoading: false,
        }))
      })
      .catch((error) => {
        addToast(CommonService.errorMessage(error), 'error')
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  async function handleOnSubmit() {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    if (createMode) {
      await ActionService.create(action)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, pageState.createMode), 'success')
          navigate(`/${entityGroupName}/${result._id}/update`)
        })
        .catch((error) => {
          if (error.endsWith('400')) {
            addToast(
              'Eine Versendung mit derselben ID existiert bereits für die Kombination aus Account und Liste schon',
              'error'
            )
          } else {
            addToast(CommonService.errorMessage(error), 'error')
          }
        })
        .finally(() => {
          setPageState((prev) => ({ ...prev, isLoading: false }))
        })
    } else {
      await ActionService.update(action)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, pageState.createMode), 'success')
          setPageState((prev) => ({ ...prev, editMode: false }))
        })
        .catch((error) => {
          addToast(CommonService.errorMessage(error), 'error')
        })
        .finally(() => {
          setPageState((prev) => ({ ...prev, isLoading: false }))
          getData()
        })
    }
  }

  const onChange = (event) => {
    const { name, value } = event.target
    switch (name) {
      default:
        setPageState((prevState) => ({
          ...prevState,
          action: {
            ...prevState.action,
            [name]: value,
          },
        }))
    }
  }

  function deleteSuccessMessage(actionId) {
    return `Die Versendung (ID: ${actionId}) wurde gelöscht.`
  }

  function openDeleteModal(actionId) {
    setPageState((prevState) => ({
      ...prevState,
      modal: {
        ...prevState.modal,
        show: true,
        title: `Lösche Versendung`,
        text: `Willst du die Versendung wirklich löschen?`,
        onCancelClicked: () =>
          setPageState((prev) => ({ ...prev, modal: { ...prev.modal, show: false } })),
        onOkayClicked: () => handleDelete(actionId),
        submitButton: 'Ja',
        backButton: 'Nein',
      },
    }))
  }

  async function handleDelete(actionId) {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await ActionService.deleteByObjectId(actionId)
      .then((response) => {
        addToast(deleteSuccessMessage(actionId), 'success')
        setPageState((prev) => ({ ...prev, modal: { ...prev.modal, show: false } }))
        navigate(`/actions/`)
      })
      .catch((error) => {
        addToast(CommonService.errorMessage(error), 'error')
      })
      .finally(() => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  async function testAction() {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await ActionService.getActionResult(action.deal.account, action._id)
      .then((response) => {
        setPageState((prev) => ({
          ...prev,
          actionResult: response,
        }))
      })
      .catch((error) => {
        addToast(CommonService.errorMessage(error), 'error')
      })
      .finally(() => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  const handleCopy = (event) => {
    navigator.clipboard.writeText(event)
  }

  const maskDisabled = !editMode && !createMode
  return (
    <>
      <div className="flex gap-2">
        <div className="flex-1">
          <DropDown
            name="deal"
            title="Kampagne"
            value={action.deal?._id}
            onChange={onChange}
            required={true}
            list={dealList}
            disabled={!createMode || !userState.isAdmin}
          />
          <DropDown
            name="status"
            title="Status"
            value={action.status}
            onChange={onChange}
            required={true}
            disabled={maskDisabled}
            list={statusList}
          />
        </div>
        <div className="flex-1">
          <DropDown
            name="type"
            title="Versandart"
            value={action.type}
            onChange={onChange}
            required={true}
            list={typeList}
            disabled={maskDisabled || !userState.isAdmin}
          />
          <div>
            <div>
              <InputDate
                name="date"
                title="Datum"
                value={new Date(action.date).toISOString().split('T')[0]}
                onChange={onChange}
                required={true}
                disabled={maskDisabled || !userState.isAdmin}
              />
            </div>
            <div>
              <InputTime
                name="time"
                title="Uhrzeit"
                value={action.time}
                onChange={onChange}
                required={true}
                disabled={maskDisabled || !userState.isAdmin}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="basis-1/3">
          <InputText
            name="sender"
            title="Absender"
            value={action.sender}
            onChange={onChange}
            required={false}
            disabled={maskDisabled}
          />
        </div>
        <div className="basis-1/3">
          <InputNumber
            name="mailsPerHour"
            title="Versand / Mails pro h"
            value={action.mailsPerHour}
            onChange={onChange}
            required={false}
            disabled={maskDisabled || !userState.isAdmin}
          />
        </div>
        <div className="basis-1/3">
          <InputText
            name="subject"
            title="Betreff"
            value={action.subject}
            onChange={onChange}
            required={false}
            disabled={maskDisabled}
          />
        </div>
      </div>
      <div className="flex">
        <div className="grow">
          <InputTextArea
            name="selection"
            title="Selektion"
            value={action.selection}
            onChange={onChange}
            required={false}
            disabled={maskDisabled}
          />
        </div>
        <div className="grow">
          <InputTextArea
            name="description"
            title="Beschreibung"
            value={action.description}
            onChange={onChange}
            required={false}
            disabled={maskDisabled}
          />
        </div>
        {action.deal?.customer?.testEmailAddresses && (
          <div className="grow">
            <InputTextArea
              name="customerTestAddresses"
              title="Übliche Kundenadressen"
              value={action.deal?.customer?.testEmailAddresses}
              required={false}
              disabled={true}
              onChange={undefined}
            />
          </div>
        )}
        <div className="grow">
          <InputTextArea
            name="testAddresses"
            title="Test Adressen für Versendung"
            value={action.testAddresses}
            onChange={onChange}
            required={false}
            disabled={maskDisabled}
          />
        </div>
      </div>
      <div className="flex gap-2">
        {!createMode && (
          <div>
            <DropDown
              name="dealAccount"
              title="Kampagnen Account"
              value={action.deal?.account}
              list={accountList}
              disabled={true}
            />
          </div>
        )}
        <div>
          <DropDown
            name="useMailSystem"
            title="Benutze Account?"
            value={action.useMailSystem}
            onChange={onChange}
            required={true}
            disabled={maskDisabled || !userState.isAdmin}
            list={ActionService.useMailSystem}
          />
        </div>
        <div>
          <DropDown
            name="mailSystem"
            title="Versand System"
            value={action.mailSystem}
            onChange={onChange}
            required={true}
            disabled={maskDisabled || !userState.isAdmin}
            list={mailSystemList}
          />
        </div>
        <div>
          <InputText
            name="mailSystemId"
            title="Email Id"
            value={action.mailSystemId}
            onChange={onChange}
            required={false}
            disabled={maskDisabled}
          />
        </div>
      </div>
      {action.useMailSystem === 'Nein' && (
        <div>
          <div>
            <InputNumber
              title="Versendet"
              name="countSent"
              value={action.countSent}
              onChange={onChange}
              required={false}
              disabled={maskDisabled || !userState.isAdmin}
            />
          </div>
          <div>
            <InputNumber
              title="Öffner"
              name="countOpened"
              value={action.countOpened}
              onChange={onChange}
              required={false}
              disabled={maskDisabled || !userState.isAdmin}
            />
          </div>
          <div>
            <InputNumber
              title="Klicks"
              name="countClicked"
              value={action.countClicked}
              onChange={onChange}
              required={false}
              disabled={maskDisabled || !userState.isAdmin}
            />
          </div>
          <div>
            <InputNumber
              title="Abmelder"
              name="countAbm"
              value={action.countAbm}
              onChange={onChange}
              required={false}
              disabled={maskDisabled || !userState.isAdmin}
            />
          </div>
        </div>
      )}
      <hr></hr>
      <div className="flex gap-2">
        <div className="flex-1">
          <Table>
            <TableHead>
              <TableHeaderRow>
                <TableHeaderCell colSpan={2}>Werbemittel Dateien der Kampagne</TableHeaderCell>
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {action.deal?.templateFiles?.length > 0 ? (
                action.deal?.templateFiles.map((file, key) => (
                  <TableRow key={key} index={key}>
                    <TableCell>
                      <p className="m-0">{file.name}</p>
                    </TableCell>
                    <TableCell>
                      <p className="text-end m-0 p-1">
                        <a
                          className="link-primary"
                          title="Datei herunterladen"
                          download={file.name}
                          href={file.base64}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                        <span
                          className="link-primary p-1"
                          title="Dateiname in Zwischenablage"
                          onClick={() => handleCopy(file.name)}
                        >
                          <FontAwesomeIcon icon={faClipboard} />
                        </span>
                      </p>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <tr>
                  <TableCell colSpan={2}>
                    <p className="m-0">Keine Dateien</p>
                  </TableCell>
                </tr>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex-1">
          <Table>
            <TableHead>
              <TableHeaderRow>
                <TableHeaderCell colSpan={2}>Blacklist Dateien der Kampagne</TableHeaderCell>
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {action.deal?.blacklistFiles?.length > 0 ? (
                action.deal?.blacklistFiles.map((file, key) => (
                  <TableRow key={key} index={key}>
                    <TableCell>
                      <p className="m-0">{file.name}</p>
                    </TableCell>
                    <TableCell>
                      <p className="text-end m-0 p-1">
                        <a
                          className="link-primary"
                          title="Datei herunterladen"
                          download={file.name}
                          href={file.base64}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                        <span
                          className="link-primary"
                          title="Dateiname in Zwischenablage"
                          onClick={() => handleCopy(file.name)}
                        >
                          <FontAwesomeIcon icon={faClipboard} />
                        </span>
                      </p>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow index={0}>
                  <TableCell colSpan={2}>
                    <p className="m-0">Keine Daten</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <div>
        <div className="flex justify-end">
          {createMode || editMode ? (
            <>
              <CancelButton
                onClick={() => setPageState((prev) => ({ ...prev, editMode: false }))}
              />
              <SaveButton
                enableSaveButton={() => enableSaveButton()}
                handleOnSubmit={() => handleOnSubmit()}
              />
            </>
          ) : (
            <>
              <BackButton />
              {action.mailSystemId && action.mailSystemId && action.deal && (
                <TestButton onClick={() => testAction()} title={'Test'} />
              )}
              {userState.isAdmin && <CopyButton onClick={() => copyAction()} />}
              <EditButton onClick={() => setPageState((prev) => ({ ...prev, editMode: true }))} />
            </>
          )}
          {!createMode && <DeleteButton onClick={() => openDeleteModal(action._id)} />}
        </div>
      </div>
      {actionResult && (
        <ComponentWrapper>
          <div>
            <h2>Testergebnis</h2>
            <Table size="sm">
              <thead>
                <td style={{ width: '10%' }}>Versand</td>
                <td style={{ width: '10%' }}>Öffner</td>
                <td style={{ width: '10%' }}>Klicks</td>
                <td style={{ width: '10%' }}>ABM</td>
                <td style={{ width: '10%' }}>Bounced</td>
              </thead>
              <tbody>
                <tr className="w-100">
                  <td>{actionResult.countSent}</td>
                  <td>
                    {actionResult.countOpened} ({actionResult.countOpenedRate}%)
                    <br />
                    {actionResult.countOpenedUnique} ({actionResult.countOpenedUniqueRate}%)
                  </td>
                  <td>
                    {actionResult.countClicked} ({actionResult.countClickedRate}%)
                    <br />
                    {actionResult.countClickedUnique} ({actionResult.countClickedUniqueRate}%)
                  </td>
                  <td>
                    {actionResult.countAbm} ({actionResult.countAbmRate}%)
                  </td>
                  <td>
                    {actionResult.countBounced} ({actionResult.countBouncedRate}%)
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ComponentWrapper>
      )}
      {modal.show === true && <SimpleModal modal={modal} />}
    </>
  )
}

export default Detail
