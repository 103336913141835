import authHeader from '../helpers/auth-header'
import http from '../helpers/http-common'

const entityGroupName = 'actions'

const typeList = [
  { name: 'Hauptversand', _id: 'HV' },
  { name: 'Nachversand', _id: 'NV' },
]

const useMailSystem = [
  { name: 'Nein', _id: 'Nein' },
  { name: 'Ja', _id: 'Ja' },
]

const monthList = [
  { _id: 1, name: 'Januar' },
  { _id: 2, name: 'Februar' },
  { _id: 3, name: 'März' },
  { _id: 4, name: 'April' },
  { _id: 5, name: 'Mai' },
  { _id: 6, name: 'Juni' },
  { _id: 7, name: 'Juli' },
  { _id: 8, name: 'August' },
  { _id: 9, name: 'September' },
  { _id: 10, name: 'Oktober' },
  { _id: 11, name: 'November' },
  { _id: 12, name: 'Dezember' },
]

function generateArrayOfYears() {
  var max = new Date().getFullYear() + 1
  var min = max - 10
  var years = []

  for (var i = max; i >= min; i--) {
    years.push({ _id: i, name: i })
  }
  return years
}

const formatDateToString = (date) => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

const initializeObject = () => {
  return {
    _id: '',
    createdBy: '',
    createdAt: '',
    lastChangedAt: '',
    lastChangedBy: '',

    deal: { _id: '' },
    date: new Date().toISOString().split('T')[0],
    time: '00:00',
    type: typeList[0]._id,
    status: 'NEU',
    testAddresses: '',
    mailSystem: null,
    mailSystemId: '',
    recipientList: 'A',
    useMailSystem: 'Ja',
    description: '',
    mailsPerHour: 0,
    selection: '',
    countSent: null,
    countClicked: null,
    countOpened: null,
    countAbm: null,
  }
}

async function getPaging(customerId, dealId, pageSize) {
  let query = '?'

  if (customerId) {
    query += `customerId=${customerId}`
  }

  if (dealId) {
    query += `&dealId=${dealId}`
  }

  if (pageSize) {
    query += `&pageSize=${pageSize}`
  }

  return await http.get(`${entityGroupName}/paging${query}`, { headers: authHeader() })
}

async function get(customerId, dealId, pageSize, page) {
  let query = '?'
  if (customerId) {
    query += `customerId=${customerId}`
  }

  if (dealId) {
    query += `&dealId=${dealId}`
  }

  if (pageSize && page) {
    query += `&pageSize=${pageSize}&page=${page}`
  }

  return await http.get(`actions${query}`, { headers: authHeader() })
}

async function copyAction(dealId, actionId) {
  return await http.get(`admin/deals/${dealId}/actions/${actionId}/copy`, { headers: authHeader() })
}

async function create(data) {
  return await http.post(`admin/deals/${data.deal}/actions`, data, { headers: authHeader() })
}

async function getByObjectId(objectId) {
  return await http.get(`actions/${objectId}`, { headers: authHeader() })
}

async function update(data) {
  return await http.put(`actions/${data._id}`, data, { headers: authHeader() })
}

async function deleteByObjectId(actionId) {
  return await http.delete(`admin/actions/${actionId}`, { headers: authHeader() })
}

async function getReport(selectedAccount, selectedCustomer, startDate, endDate) {
  const queryParam = selectedCustomer ? `?customerId=${selectedCustomer}` : ''
  const url = `admin/report/${selectedAccount}/${formatDateToString(
    startDate
  )}/${formatDateToString(endDate)}${queryParam}`

  return await http.get(url, { headers: authHeader() })
}

async function getReportDetails(selectedAccount, dealIds) {
  return await http.get(`admin/details/${selectedAccount}/${dealIds}`, { headers: authHeader() })
}

async function getDashboard() {
  const url = `dashboard`
  return await http.get(url, { headers: authHeader() })
}

async function getCalendar(selectedAccount, selectedStatus, startDate, endDate) {
  const formattedDate = formatDateToString(startDate)
  const formattedEndDate = formatDateToString(endDate)

  const queryParams = new URLSearchParams()
  if (selectedStatus) {
    queryParams.append('status', selectedStatus)
  }

  const url = `calendar/${selectedAccount}/${formattedDate}/${formattedEndDate}`
  const fullUrl = queryParams.toString() ? `${url}?${queryParams}` : url

  return await http.get(fullUrl, { headers: authHeader() })
}

async function getActionResult(accountId, actionId) {
  return await http.get(`report/account/${accountId}/action/${actionId}`, { headers: authHeader() })
}

async function validateAction(actionId) {
  return await http.get(`admin/actions/${actionId}/validate`, { headers: authHeader() })
}

const service = {
  typeList,
  useMailSystem,
  monthList,
  getActionResult,
  validateAction,
  copyAction,
  generateArrayOfYears,
  initializeObject,
  get,
  getReport,
  getCalendar,
  getDashboard,
  getReportDetails,
  create,
  getByObjectId,
  update,
  deleteByObjectId,
  getPaging,
  formatDateToString,
}

export default service
