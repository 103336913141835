import authHeader from '../helpers/auth-header'
import http from '../helpers/http-common'

const initializeObject = () => {
  return {
    _id: '',
    createdBy: '',
    createdAt: '',
    lastChangedAt: '',
    lastChangedBy: '',
    colorPrimary: '',
    colorSecondary: '',

    name: '',
    credentials: [],
  }
}

async function getList() {
  let list = [{ _id: '', name: '-' }]
  const result = await this.get()
  return list.concat(result)
}

async function get() {
  return await http.get('accounts', { headers: authHeader() })
}

async function getColors() {
  return await http.get('accounts/colors', { headers: authHeader() })
}

async function getByObjectId(objectId) {
  return await http.get(`admin/accounts/${objectId}`, { headers: authHeader() })
}

async function create(data) {
  return await http.post('admin/accounts', data, { headers: authHeader() })
}

async function update(data) {
  return await http.put(`admin/accounts/${data._id}`, data, { headers: authHeader() })
}

async function deleteByObjectId(objectId) {
  return await http.delete(`admin/accounts/${objectId}`, { headers: authHeader() })
}

const service = {
  initializeObject,
  get,
  getColors,
  getList,
  create,
  getByObjectId,
  update,
  deleteByObjectId,
}

export default service
