function SimpleModal({ modal }) {
  return (
    <div className={`${modal.show ? 'fixed inset-0 overflow-y-auto' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
          <div className="bg-gray-50 px-4 py-3 sm:px-6">
            <h3 className="text-lg font-medium text-gray-900">{modal.title}</h3>
          </div>
          <div className="px-4 py-2">
            <p className="text-sm text-gray-500">{modal.text}</p>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={modal.onOkayClicked}
            >
              {modal.submitButton}
            </button>
            <button
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={modal.onCancelClicked}
            >
              {modal.backButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleModal
