export const formatDate = (input, weekday) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  if (weekday) {
    options.weekday = 'long'
  }

  return new Date(input).toLocaleDateString('de-DE', options)
}

export const formatDateAndTime = (input) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  let date = new Date(input)
  return date.toLocaleTimeString('de-DE', options)
}
