import http from '../helpers/http-common'

export const authenticationService = {
  login,
  logout,
  getCurrentUser,
  isAdmin,
}

async function login(user) {
  const result = await http.post('signin', user)
  localStorage.setItem('currentUser', JSON.stringify(result))
  return result
}

function getCurrentUser() {
  return JSON.parse(localStorage.getItem('currentUser'))
}

function logout() {
  localStorage.removeItem('currentUser')
}

function isAdmin() {
  const user = this.getCurrentUser()
  return user.isAdmin
}
