import {
  BackButton,
  CancelButton,
  DeleteButton,
  EditButton,
  SaveButton,
} from 'components/Button/Buttons'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CommonService from 'services/common.service'
import Service from 'services/config.service'
import { useToastContext } from '../../context/ToastContext'
import { InputText, InputTextArea } from '../Common/Inputs'
import SimpleModal from '../Common/SimpleModal'

const entityGroupName = `configs`
const entityName = 'Config'

const ConfigForm = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const { objectId } = useParams()
  const [pageState, setPageState] = useState({
    config: Service.initializeObject(),
    editMode: false,
    createMode: typeof objectId === 'undefined',
    modal: { show: false },
  })

  const { config, editMode, createMode, modal } = pageState

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setPageState((prev) => ({ ...prev, isLoading: true }))
    await Promise.all([createMode ? Service.initializeObject() : Service.getByObjectId(objectId)])
      .then(([configResult]) => {
        setPageState((prev) => ({ ...prev, config: configResult, isLoading: false }))
      })
      .catch((error) => {
        addToast(CommonService.errorMessage(error), 'error')
        setPageState((prev) => ({ ...prev, isLoading: false }))
      })
  }

  const onChange = (event) => {
    const { name, value } = event.target
    switch (name) {
      default:
        setPageState((prevState) => ({
          ...prevState,
          config: {
            ...prevState.config,
            [name]: value,
          },
        }))
    }
  }

  async function handleOnSubmit() {
    if (createMode) {
      await Service.create(config)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, createMode), 'success')
          navigate(`/${entityGroupName}/${result._id}/update`)
        })
        .catch((error) => {
          addToast(CommonService.errorMessage(error), 'error')
        })
        .finally(() => {
          setPageState((prev) => ({ ...prev, isLoading: false }))
        })
    } else {
      await Service.update(config)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, createMode), 'success')
          setPageState((prev) => ({ ...prev, editMode: false }))
        })
        .catch((error) => {
          addToast(CommonService.errorMessage(error), 'error')
        })
        .finally(() => {
          setPageState((prev) => ({ ...prev, isLoading: false }))
          getData()
        })
    }
  }

  function deleteSuccessMessage(objectId) {
    return `Die Config (ID: ${objectId}) wurde gelöscht.`
  }

  async function openDeleteModal(objectId) {
    setPageState((prevState) => ({
      ...prevState,
      modal: {
        ...prevState.modal,
        show: true,
        title: `Lösche Config`,
        text: `Willst du die Config wirklich löschen?`,
        onCancelClicked: () =>
          setPageState((prev) => ({ ...prev, modal: { ...prev.modal, show: false } })),
        onOkayClicked: () => handleDelete(objectId),
        submitButton: 'Ja',
        backButton: 'Nein',
      },
    }))
  }

  async function handleDelete(dealId) {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Service.deleteByObjectId(dealId)
      .then((response) => {
        addToast(deleteSuccessMessage(dealId), 'success')
        setPageState((prev) => ({ ...prev, modal: { ...prev.modal, show: false } }))
        navigate(`/deals/`)
      })
      .catch((error) => {
        addToast(CommonService.errorMessage(error), 'error')
      })
      .finally(() => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  const enableSaveButton = () => {
    return config.name && config.config
  }

  return (
    <>
      <div className="flex flex-col">
        <InputText
          name="name"
          title="Name"
          value={config.name}
          onChange={onChange}
          required={true}
          disabled={!createMode}
        />
        <InputTextArea
          name="config"
          title="Config"
          value={config.config}
          onChange={onChange}
          required={true}
          disabled={!editMode && !createMode}
        />
      </div>
      <div className="flex justify-end">
        {createMode || editMode ? (
          <>
            <CancelButton onClick={() => setPageState((prev) => ({ ...prev, editMode: false }))} />
            <SaveButton
              enableSaveButton={() => enableSaveButton()}
              handleOnSubmit={() => handleOnSubmit()}
            />
            {!createMode && <DeleteButton onClick={() => openDeleteModal(config._id)} />}
          </>
        ) : (
          <>
            <BackButton />
            <EditButton onClick={() => setPageState((prev) => ({ ...prev, editMode: true }))} />
            {!createMode && <DeleteButton onClick={() => openDeleteModal(config._id)} />}
          </>
        )}
      </div>
      {modal.show === true && <SimpleModal modal={modal} />}
    </>
  )
}

export default ConfigForm
