async function onHandleChange(setData, event) {
  const { name, value } = event.target
  switch (name) {
    default:
      setData((prevState) => ({
        ...prevState,
        [name]: value,
      }))
  }
}

const successMessage = (entityName, createMode) =>
  `${entityName} wurde in der Datenbank ${createMode ? 'angelegt' : 'bearbeitet'}`
const errorMessage = (error) => `Technischer Fehler: ${error}`

const service = {
  onHandleChange,
  successMessage,
  errorMessage,
}

export default service
