import cx from 'classnames'

// Base Input Component
const InputBase = ({
  type,
  title,
  name,
  value,
  onChange,
  disabled = false,
  required = false,
  autoComplete = 'off',
}) => {
  const inputClassNames = cx(
    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-200 disabled:cursor-not-allowed'
  )

  return (
    <div className="m-2 p-2">
      <label className="block mb-2 text-sm font-medium text-black-900" htmlFor={name}>
        {title}
        {required && ' *'}
      </label>
      <input
        type={type}
        onChange={onChange}
        name={name}
        placeholder={title}
        value={value}
        disabled={disabled}
        required={required}
        autoComplete={autoComplete}
        className={inputClassNames}
      />
    </div>
  )
}

// FileUpload Component
export const FileUpload = ({
  title,
  name,
  value,
  onChange: handleFileChange,
  disabled = false,
  required = false,
}) => {
  const id = `${name}_file`
  return (
    <form method="post" encType="multipart/form-data">
      <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor={id}>
        {title}
      </label>
      <br />
      <input
        id={id}
        className={cx(
          'block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none disabled:bg-gray-200 disabled:cursor-not-allowed'
        )}
        name={name}
        value={value}
        type="file"
        onChange={handleFileChange}
        disabled={disabled}
        required={required}
      />
    </form>
  )
}

// DropDown Component
export const DropDown = ({
  list,
  title,
  name,
  value,
  onChange,
  disabled = false,
  required = false,
}) => {
  const inputClassNames = cx(
    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-200 disabled:cursor-not-allowed'
  )

  return (
    <div className="m-2 p-2">
      <label htmlFor={name} className="block mb-2 text-sm font-medium text-black-900">
        {title}
        {required && ' *'}
      </label>
      <select
        onChange={onChange}
        name={name}
        disabled={disabled}
        required={required}
        value={value}
        className={inputClassNames}
      >
        {list.map((item) => (
          <option key={item._id} value={item._id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  )
}

// InputTextArea Component
export const InputTextArea = ({
  title,
  name,
  value,
  onChange,
  disabled = false,
  required = false,
}) => {
  return (
    <div className="m-2 p-2">
      <label className="block mb-2 text-sm font-medium text-black-900" htmlFor={name}>
        {title}
        {required && ' *'}
      </label>
      <textarea
        onChange={onChange}
        name={name}
        placeholder={title}
        value={value}
        disabled={disabled}
        required={required}
        className={
          'h-32 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-200 disabled:text-black disabled:cursor-not-allowed'
        }
      />
    </div>
  )
}

// Specific Input Components using InputBase
export const InputNumber = (props) => <InputBase type="number" {...props} />
export const InputText = (props) => <InputBase type="text" {...props} />
export const InputEmail = (props) => <InputBase type="email" {...props} />
export const InputPhone = (props) => <InputBase type="tel" {...props} />
export const InputDate = (props) => <InputBase type="date" {...props} />
export const InputTime = (props) => <InputBase type="time" {...props} />
export const InputPassword = (props) => <InputBase type="password" {...props} />
