export const initialUserState = null

export const userReducer = (userState, userAction) => {
  if (userAction.type === 'USER') {
    return userAction.payload
  }

  if (userAction.type === 'CLEAR') {
    return null
  }

  return userState
}
