export const getDataForPagination = (totalResults, pageSize, selectedPage, setPageCallback) => {
  const minPage = selectedPage - 5
  const maxPage = selectedPage + 5

  var pageCount = Math.floor(totalResults / pageSize)
  let pages = []
  for (let i = 1; i <= pageCount; i++) {
    if (
      (selectedPage < 5 && i <= 9) ||
      (pageCount < 10 && i < 10) ||
      (i > minPage && i < maxPage) ||
      (selectedPage > pageCount - 5 && i >= pageCount - 8)
    ) {
      pages.push(i)
    }
  }

  return {
    pages,
    pageCount,
    totalResults,
    selectedPage,
    setPageCallback,
  }
}
