import { BackButton, CancelButton, EditButton, SaveButton } from 'components/Button/Buttons'
import { formatDateAndTime } from '../../helpers/date-helper'
import { InputText } from './Inputs'

const DetailFooter = ({
  data,
  showSaveButton,
  showDatabaseInfo,
  saveButtonDisabled,
  handleOnSubmit,
  handleOnEditClicked,
  handleOnCancelClicked,
}) => {
  return (
    <>
      <div className="flex justify-end gap-1">
        {showSaveButton ? (
          <>
            <CancelButton onClick={handleOnCancelClicked} />
            <SaveButton
              handleOnSubmit={handleOnSubmit}
              enableSaveButton={() => saveButtonDisabled}
            />
          </>
        ) : (
          <>
            <BackButton />
            <EditButton onClick={handleOnEditClicked} />
          </>
        )}
      </div>
      {showDatabaseInfo && (
        <>
          <h3 className="text-center p-2 mt-4">Technische Info </h3>
          <hr className="my-2" />
          <div className="flex gap-2">
            <div className="basis-1/3">
              <InputText
                name="createdAt"
                title="Erstellt am"
                value={formatDateAndTime(data.createdAt)}
                disabled
                onChange={undefined}
              />
            </div>
            <div className="basis-1/3">
              <InputText
                name="lastChangedAt"
                title="Zuletzt geändert am"
                value={formatDateAndTime(data.lastChangedAt)}
                disabled
                onChange={undefined}
              />
            </div>
            <div className="basis-1/3">
              <InputText
                name="id"
                title="Datenbank ID"
                value={data._id}
                disabled
                onChange={undefined}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default DetailFooter
