import { CreateButton, DeleteButton, OpenButton } from 'components/Button/Buttons'
import {
  ActionIconWrapper,
  ComponentWrapper,
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table/Table'
import { TableBody } from 'components/Table/TableBody'
import { TableHead } from 'components/Table/TableHead'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Service from 'services/contact.service'
import { getCustomerList } from 'services/customer.service'
import { useToastContext } from '../../context/ToastContext'
import { DropDown } from '../Common/Inputs'
import Loading from '../Common/Loading'
import SimpleModal from '../Common/SimpleModal'

const Index = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const [modal, setModal] = useState({ show: false })

  const entityGroupName = 'contacts'
  const entityName = 'Kontakt'
  const notFound = `Kein ${entityName} gefunden`

  function errorMessage(error) {
    return `Technischer Fehler: ${error}`
  }

  function deleteSuccessMessage(contactId) {
    return `Der ${entityName} (ID: ${contactId}) wurde gelöscht.`
  }

  const [pageState, setPageState] = useState({
    isLoading: true,
    customers: [],
    selectedCustomer: [],
    contacts: [],
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
      contacts: [],
    }))
    await Promise.all([Service.get(pageState.selectedCustomer), getCustomerList()])
      .then(([contacts, customers]) => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
          contacts: contacts,
          customers: customers,
        }))
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  async function handleDelete(contactId) {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Service.deleteByObjectId(contactId)
      .then((response) => {
        addToast(deleteSuccessMessage(contactId), 'success')
        setModal({ show: false })
        getData()
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
      })
      .finally(() => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  async function openDeleteModal(contactId, name) {
    setModal({
      show: true,
      title: `Lösche ${entityName}`,
      text: `Willst du "${name}" wirklich löschen?`,
      onCancelClicked: () => setModal({ show: false }),
      onOkayClicked: () => handleDelete(contactId),
      submitButton: 'Ja',
      backButton: 'Nein',
    })
  }

  async function onCustomerFilterChanged(event) {
    const { value } = event.target
    setPageState((prev) => ({
      ...prev,
      selectedCustomer: value,
    }))
    getData()
  }

  if (pageState.isLoading) {
    return <Loading />
  } else {
    return (
      <>
        <ComponentWrapper>
          <div className="text-center">
            <CreateButton
              onClick={() => navigate(`/${entityGroupName}/create`)}
              title={`${entityName} anlegen`}
            />
          </div>
        </ComponentWrapper>
        <ComponentWrapper>
          <div className="flex">
            <div className="basis-1/3">
              <DropDown
                list={pageState.customers}
                title="Filter Kunde"
                name="selectedCustomer"
                value={pageState.selectedCustomer}
                onChange={onCustomerFilterChanged}
              />
            </div>
          </div>
          <Table>
            <TableHead>
              <TableHeaderRow>
                <TableCell>Kunde</TableCell>
                <TableCell>Nachname</TableCell>
                <TableCell>Vorname</TableCell>
                <TableCell></TableCell>
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {typeof pageState.contacts == 'undefined' ||
              pageState.contacts == null ||
              pageState.contacts.length <= 0 ? (
                <TableRow index={0}>
                  <TableCell colSpan={4} className="text-center">
                    {notFound}
                  </TableCell>
                </TableRow>
              ) : (
                pageState.contacts.map((item, index) => (
                  <TableRow key={item._id} index={index}>
                    <TableCell>{item.customer ? item.customer.name : '-'}</TableCell>
                    <TableCell>{item.lastName}</TableCell>
                    <TableCell>{item.firstName}</TableCell>
                    <TableCell>
                      <ActionIconWrapper>
                        <OpenButton
                          onClick={() => navigate(`/${entityGroupName}/${item._id}/update`)}
                        />
                        <DeleteButton
                          onClick={() =>
                            openDeleteModal(item._id, `${item.lastName} (${item.firstName})`)
                          }
                        />
                      </ActionIconWrapper>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </ComponentWrapper>
        {modal.show === true && <SimpleModal modal={modal} />}
      </>
    )
  }
}

export default Index
