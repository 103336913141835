import cx from 'classnames'

export const StatusButton = ({ status }) => {
  let bgColor = 'bg-transparent' // Correct class name

  switch (status) {
    case 'Werbemittel':
      bgColor = 'bg-red-100'
      break
    case 'Getimed':
      bgColor = 'bg-blue-100'
      break
    case 'Werbemittel Optimierung':
      bgColor = 'bg-red-200'
      break
    case 'Freigabe':
      bgColor = 'bg-green-200'
      break
    case 'Warte auf Werbemittel Optimierung':
      bgColor = 'bg-red-300'
      break
    default:
      bgColor = 'bg-gray-200'
      break
  }

  const className = cx('rounded-lg p-1 uppercase text-xs font-bold text-center', bgColor)
  return <div className={className}>{status}</div>
}
