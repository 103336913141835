import authHeader from '../helpers/auth-header'
import http from '../helpers/http-common'

const initializeObject = () => {
  return {
    _id: '',
    createdBy: '',
    createdAt: '',
    lastChangedAt: '',
    lastChangedBy: '',

    name: '',
    apiUrl: '',
  }
}

async function get() {
  return await http.get(`mailSystems`, { headers: authHeader() })
}

async function create(data) {
  return http.post('admin/mailSystems', data, { headers: authHeader() })
}

async function getByObjectId(objectId) {
  return await http.get(`admin/mailSystems/${objectId}`, { headers: authHeader() })
}

async function update(data) {
  return await http.put(`admin/mailSystems/${data._id}`, data, { headers: authHeader() })
}

async function deleteByObjectId(objectId) {
  return await http.delete(`admin/mailSystems/${objectId}`, { headers: authHeader() })
}

const service = {
  initializeObject,
  get,
  create,
  getByObjectId,
  update,
  deleteByObjectId,
}

export default service
