import {
  ArrowLeftStartOnRectangleIcon,
  AtSymbolIcon,
  BuildingOfficeIcon,
  CalculatorIcon,
  CalendarDaysIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  CogIcon,
  EnvelopeIcon,
  ListBulletIcon,
  MegaphoneIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import cx from 'classnames'
import { AccountForm } from 'components/Account/Account'
import Accounts from 'components/Account/Accounts'
import Action from 'components/Action/Action'
import Actions from 'components/Action/Actions'
import AuthVerify from 'components/AuthVerify'
import Calendar from 'components/Calendar'
import ConfigForm from 'components/Configs/Config'
import Configs from 'components/Configs/Configs'
import Contact from 'components/Contacts/Contact'
import Contacts from 'components/Contacts/Contacts'
import { CustomerForm } from 'components/Customers/Customer'
import Customers from 'components/Customers/Customers'
import { DealForm } from 'components/Deals/Deal'
import Deals from 'components/Deals/Deals'
import { MailSystemForm } from 'components/MailSystem/MailSystem'
import MailSystems from 'components/MailSystem/MailSystems'
import Report from 'components/Report'
import useLocalStorage from 'hooks/useLocalStorage'
import { Route, Routes } from 'react-router-dom'
import { authenticationService } from 'services/authentication.service'
import Dashboard from './Dashboard/dashboard'
import InfoHeader from './InfoHeader'

export const AppContent = ({ userState, dispatch }) => {
  const [showDashboard, setShowDashboard] = useLocalStorage('show-dashboard', true)

  const content = cx('w-full flex justify-center', showDashboard ? 'pt-[370px]' : 'pt-[170px]')

  return (
    <>
      <div className="fixed w-full  z-50 shadow-sm drop-shadow-xl bg-white">
        <InfoHeader userState={userState} />
        <header className="bg-gray-100 h-[40px] mb-[8px] shadow-sm py-2 border-b border-t border-gray-400 ">
          <nav className="flex gap-8 justify-center items-center h-full ">
            <a href="/calendar" title="Kalender" className="h-6 w-6">
              <CalendarDaysIcon className="h-6 w-6 hover:text-blue-700 " />
            </a>
            <a href="/report" title="Report" className={`${!userState?.isAdmin && 'hidden'}`}>
              <CalculatorIcon className="h-6 w-6 hover:text-blue-700" />
            </a>
            <a href="/actions" title="Versendungen" className="h-6 w-6">
              <EnvelopeIcon className="h-6 w-6 hover:text-blue-700" />
            </a>
            <a href="/deals" title="Kampagnen" className={`${!userState?.isAdmin && 'hidden'}`}>
              <MegaphoneIcon className="h-6 w-6 hover:text-blue-700" />
            </a>
            <a href="/customers" title="Kunden" className={`${!userState?.isAdmin && 'hidden'}`}>
              <BuildingOfficeIcon className="h-6 w-6 hover:text-blue-700" />
            </a>
            <a href="/contacts" title="Kontakte" className={`${!userState?.isAdmin && 'hidden'}`}>
              <UserGroupIcon className="h-6 w-6 hover:text-blue-700" />
            </a>
            <a href="/accounts" title="Listen" className={`${!userState?.isAdmin && 'hidden'}`}>
              <ListBulletIcon className="h-6 w-6 hover:text-blue-700" />
            </a>
            <a
              href="/mailsystems"
              title="Mailsysteme"
              className={`${!userState?.isAdmin && 'hidden'}`}
            >
              <AtSymbolIcon className="h-6 w-6 hover:text-blue-700" />
            </a>
            <a href="/configs" title="Settings" className={`${!userState?.isAdmin && 'hidden'}`}>
              <CogIcon className="h-6 w-6 hover:text-blue-700" />
            </a>
            <div className="p-2 float-end rounded-lg bg-red-300 hover:bg-red-400 flex justify-center items-center">
              <ArrowLeftStartOnRectangleIcon
                className="h-4 w-4 cursor-pointer"
                onClick={() => {
                  authenticationService.logout()
                  window.location.reload(true)
                }}
                title="Logout"
              />
            </div>
          </nav>
        </header>
        <div className="w-full px-4 mb-[8px] h-[30px] flex justify-center items-center text-xs">
          <button
            className="flex justify-center items-center gap-2"
            onClick={() => setShowDashboard(!showDashboard)}
          >
            {showDashboard ? (
              <>
                <ChevronDoubleUpIcon className="w-3 h-3" />
                Dashboard ausblenden
                <ChevronDoubleUpIcon className="w-3 h-3" />
              </>
            ) : (
              <>
                <ChevronDoubleDownIcon className="w-3 h-3" />
                Dashboard einblenden
                <ChevronDoubleDownIcon className="w-3 h-3" />
              </>
            )}
          </button>
        </div>
        {showDashboard && <Dashboard />}
      </div>
      <div className={content}>
        <div className="max-w-screen-xl w-full">
          <Routes>
            <Route
              path="/"
              element={
                <p className="text-center">
                  Du bist eingeloggt als {userState.username}. Deine Rolle ist: {userState.roles[0]}
                  . <br />
                  Deine Anmeldung hält 24 Stunden ab dem Zeitpunkt des Logins. Navigieren über
                  obiges Menü zur gewünschten Funktion. Viel Spaß.
                </p>
              }
            />
            <Route path="/mailsystems/create" element={<MailSystemForm key="create" />} />
            <Route path="/mailsystems/:objectId/update" element={<MailSystemForm key="update" />} />
            <Route path="/accounts/create" element={<AccountForm key="create" />} />
            <Route path="/accounts/:objectId/update" element={<AccountForm key="update" />} />
            <Route path="/configs" element={<Configs />} />
            <Route path="/configs/create" element={<ConfigForm key="create" />} />
            <Route path="/configs/:objectId/update" element={<ConfigForm key="update" />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/mailsystems" element={<MailSystems />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customers/create" element={<CustomerForm key="create" />} />
            <Route path="/customers/:objectId/update" element={<CustomerForm key="update" />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/contacts/create" element={<Contact key="create" />} />
            <Route path="/contacts/:objectId/update" element={<Contact key="update" />} />
            <Route path="/deals" element={<Deals />} />
            <Route path="/deals/create" element={<DealForm key="create" />} />
            <Route path="/deals/:objectId/update" element={<DealForm key="update" />} />
            <Route path="/report" element={<Report key="report" />} />
            <Route path="/actions" element={<Actions />} />
            <Route path="/actions/create" element={<Action key="create" />} />
            <Route path="/actions/:objectId/update" element={<Action key="update" />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="*" element={<h1>Page Not Found</h1>} />
          </Routes>
        </div>
      </div>
      <AuthVerify
        logOut={() => {
          authenticationService.logout()
          window.location.reload(true)
        }}
      />
    </>
  )
}

export default AppContent
