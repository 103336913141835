import { de } from 'date-fns/locale'
import { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useToastContext } from '../context/ToastContext'
import { formatDate } from '../helpers/date-helper'
import { getStaticRanges } from '../helpers/datepickerDefaultRanges'
import useLocalStorage from '../hooks/useLocalStorage'
import AccountService from '../services/account.service'
import ActionService from '../services/action.service'
import ConfigService from '../services/config.service'
import { DropDown } from './Common/Inputs'
import Loading from './Common/Loading'
import { StatusButton } from './Common/StatusButton'
import { ComponentWrapper, Table, TableCell, TableHeaderRow, TableRow } from './Table/Table'
import { TableHead } from './Table/TableHead'

export default function CalendarPage() {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const staticRanges = getStaticRanges(de)

  const [pageState, setPageState] = useState({
    initialLoadFinished: false,
    isLoading: true,
    accountList: [],
    yearList: [],
    monthList: [],
    statusList: [],
    data: [],
    accountColors: [],
    colorPrimary: '0,0,0',
    colorSecondary: '0,0,0',
    calendarLoading: true,
    showEmptyRows: true,
    selectedStatus: null,
  })

  const { colorPrimary, colorSecondary, initialLoadFinished, statusList } = pageState

  const currentDate = new Date()
  const begin = new Date(2020, 0, 1)
  const firstDayOfMonth = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 1)
  const lastDayOfMonth = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 2, 0)
  const [startDate, setStartDate] = useLocalStorage('calendar-start-date', firstDayOfMonth)
  const [endDate, setEndDate] = useLocalStorage('calendar-end-date', lastDayOfMonth)
  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate)
    setEndDate(ranges.selection.endDate)
  }

  const selectionRange = {
    startDate: new Date(Date.parse(startDate)),
    endDate: new Date(Date.parse(endDate)),
    key: 'selection',
  }

  const [selectedAccount, setSelectedAccount] = useLocalStorage('calendar-selected-account', null)
  const [selectedStatus, setSelectedStatus] = useLocalStorage('calendar-selected-status', null)
  const [showEmpty, setShowEmpty] = useLocalStorage('calendar-showEmpty', 'ja')

  async function getData() {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))

    const accounts = await AccountService.get()
    let statusConfig = await ConfigService.getConfigObject('VersendungStatusListe')
    statusConfig.statusList.unshift({ _id: '', name: 'Alle' })
    const accountColors = await AccountService.getColors()

    if (statusConfig == null || statusConfig.statusList == null) {
      navigate(`/accounts/`)
      addToast('Die Config für die VersendungStatusListe wurde nicht gefunden.', 'error')
    }

    if (accounts == null || accounts.length <= 0) {
      navigate(`/accounts/`)
      addToast(
        'Es sind keine Accounts angelegt. Es muss mindestens ein Account existieren.',
        'error'
      )
    }

    setPageState((prev) => ({
      ...prev,
      accountList: accounts,
      statusList: statusConfig.statusList,
      accountColors: accountColors,
      isLoading: false,
      initialLoadFinished: true,
    }))
  }

  const getCalendar = async () => {
    setPageState((prev) => ({
      ...prev,
      calendarLoading: true,
    }))
    const tempSelectedAccount = selectedAccount ?? pageState.accountList[0]._id
    const tempSelectedStatus = selectedStatus ?? pageState.statusList[0]._id
    const account = pageState.accountColors.find((x) => x.id === tempSelectedAccount)
    if (tempSelectedAccount) {
      try {
        const result = await ActionService.getCalendar(
          tempSelectedAccount,
          tempSelectedStatus,
          new Date(Date.parse(startDate)),
          new Date(Date.parse(endDate))
        )

        setSelectedAccount(tempSelectedAccount)
        setPageState((prev) => ({
          ...prev,
          data: result,
          colorPrimary: account.colorPrimary,
          colorSecondary: account.colorSecondary,
          calendarLoading: false,
        }))
      } catch (error) {
        console.error('Error fetching calendar data:', error)
        // Handle error as needed, such as displaying an error message
      }
    }
  }

  useEffect(() => {
    if (initialLoadFinished) getCalendar()
  }, [initialLoadFinished, startDate, endDate, selectedAccount, showEmpty, selectedStatus])

  useEffect(() => {
    getData()
  }, [])

  const isAllEmpty = pageState.data.dayArray?.every((day) => day.actions.length === 0) // Check if all day.actions are empty

  if (pageState.isLoading) {
    return <Loading />
  } else {
    return (
      <div>
        <ComponentWrapper>
          <div className="flex">
            <div className="flex-1 pr-2">
              <DropDown
                list={pageState.accountList}
                title="Account"
                name="selectedAccount"
                value={selectedAccount}
                onChange={(event) => setSelectedAccount(event.target.value)}
              />
              <DropDown
                list={[
                  { key: 'ja', _id: 'ja', name: 'Ja' },
                  { key: 'ja', _id: 'nein', name: 'Nein' },
                ]}
                title="Zeige Leere Tage"
                name="showEmpty"
                value={showEmpty}
                onChange={(event) => setShowEmpty(event.target.value)}
              />
            </div>
            <div className="flex-1 pl-2">
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                minDate={begin}
                weekStartsOn={1}
                locale={de}
                staticRanges={staticRanges}
                inputRanges={[]}
                dateDisplayFormat={'dd.MM.yyyy'}
              />
            </div>
          </div>
        </ComponentWrapper>
        <ComponentWrapper>
          <div className="flex gap-1 justify-center items-center p-2 text-xs">
            {pageState.data.statusArray ? (
              statusList.map((status, index, array) => (
                <div key={status.name} className="flex">
                  <a
                    className={`cursor-pointer hover:text-blue-500 transition-colors ${
                      selectedStatus === status._id ? 'font-bold text-blue-700' : 'text-gray-700'
                    }`}
                    onClick={() => setSelectedStatus(status._id)}
                  >
                    {status.name}
                    <span className="text-gray-500">
                      ({pageState.data.statusArray[status.name] ?? 0})
                    </span>
                  </a>
                  {index < array.length - 1 && <span className="text-gray-400 mx-2">|</span>}
                </div>
              ))
            ) : (
              <a
                className="cursor-pointer text-gray-700 hover:text-blue-500 transition-colors"
                onClick={() => setSelectedStatus('')}
              >
                {' > Alle Status < '}
              </a>
            )}
          </div>
        </ComponentWrapper>
        <ComponentWrapper>
          {!pageState.calendarLoading && pageState.data.dayArray?.length > 0 ? (
            <Table>
              <TableHead>
                <TableHeaderRow>
                  <th className="py-2 pl-2">Zeit</th>
                  <th className="py-2 pl-2">Mails</th>
                  <th className="py-2 pl-2">/Std</th>
                  <th className="py-2 pl-2">Typ/ID</th>
                  <th className="py-2 pl-2">Status</th>
                  <th className="py-2 pl-2">Kampagne</th>
                  <th className="py-2 pl-2 pr-2">Selektion</th>
                </TableHeaderRow>
              </TableHead>
              <tbody>
                {isAllEmpty ? (
                  <TableRow>
                    <TableCell colSpan={7} className="text-center">
                      Keine Versendungen {selectedStatus ? `für Status ${selectedStatus}` : ''}
                    </TableCell>
                  </TableRow>
                ) : (
                  pageState.data.dayArray.reverse().map((day, index) => {
                    if (showEmpty === 'ja' || day.actions.length > 0)
                      return (
                        <Day
                          key={index}
                          day={day}
                          colorPrimary={colorPrimary}
                          colorSecondary={colorSecondary}
                        />
                      )
                    else return null
                  })
                )}
              </tbody>
            </Table>
          ) : (
            <Loading />
          )}
        </ComponentWrapper>
      </div>
    )
  }
}

function Day({ day, colorPrimary, colorSecondary }) {
  const rowBgColor = getColor(new Date(day.realDate).getDay(), true, colorPrimary, colorSecondary)

  return (
    <>
      <tr
        key={uuidv4()}
        style={{
          backgroundColor: rowBgColor,
        }}
        className="border-t-4 border-black"
      >
        <td colSpan={7} className="p-2 uppercase font-bold">
          {formatDate(day.realDate, true)}
        </td>
      </tr>
      {day.actions.length > 0 ? (
        day.actions
          .reverse()
          .map((action, index) => (
            <ActionRow key={index} action={action} day={day} bgColor={rowBgColor} />
          ))
      ) : (
        <NoActionRow bgColor={rowBgColor} />
      )}
    </>
  )
}

const NoActionRow = ({ bgColor }) => {
  return (
    <tr
      style={{
        backgroundColor: bgColor,
      }}
    >
      <td colSpan={7} className="py-2"></td>
    </tr>
  )
}

const ActionRow = ({ action, bgColor }) => {
  const openInNewTab = (id) => {
    // Open the link in a new tab
    window.open(
      `${window.location.protocol}//${window.location.host}/actions/${id}/update`,
      '_blank'
    )
  }

  return (
    <tr
      style={{
        backgroundColor: bgColor,
      }}
      className={`${
        action.type === 'HV' ? 'font-bold' : ''
      } hover:bg-gray-50 cursor-pointer border-y border-gray-700 text-xs`}
      onClick={() => openInNewTab(action._id)}
      title={`Öffne "${action.type} / ${action.mailSystemId ?? '- ? -'}"`}
    >
      <td className="py-2 pl-2">{action.time}</td>
      <td className="py-2 pl-2">{action.deal.bookedMails}</td>
      <td className="py-2 pl-2">{action.mailsPerHour}</td>
      <td className="py-2 pl-2">
        {action.type}/{action.mailSystemId ?? '- ? -'}
      </td>
      <td className="py-2 pl-2">
        <StatusButton status={action.status} />
      </td>
      <td className="py-2 pl-2">{action.deal.name}</td>
      <td className="py-2 pl-2 pr-2">{action.selection}</td>
    </tr>
  )
}

const hexToRGB = (hex, opacity) => {
  let alpha = false,
    h = hex.slice(hex.startsWith('#') ? 1 : 0)
  if (h.length === 3) h = [...h].map((x) => x + x).join('')
  else if (h.length === 8) alpha = true
  h = parseInt(h, 16)
  return (
    'rgb' +
    (alpha ? 'a' : '') +
    '(' +
    (h >>> (alpha ? 24 : 16)) +
    ', ' +
    ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
    ', ' +
    ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
    (alpha ? `, ${h & 0x000000ff}` : '') +
    (opacity ? ',0.8' : '') +
    ')'
  )
}

const getColor = (day, opacity, colorPrimary, colorSecondary) => {
  if (day === 0 || day === 6) {
    return `${hexToRGB(colorSecondary, opacity)}`
  }

  return `${hexToRGB(colorPrimary, opacity)}`
}
