import {
  ActionIconWrapper,
  ComponentWrapper,
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table/Table'
import { TableBody } from 'components/Table/TableBody'
import { TableHead } from 'components/Table/TableHead'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Service from 'services/account.service'
import { useToastContext } from '../../context/ToastContext'
import { CreateButton, OpenButton } from '../Button/Buttons'
import Loading from '../Common/Loading'

const Index = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()

  const entityGroupName = 'accounts'
  const entityName = 'Account'
  const notFound = `Keine Accounts gefunden`

  function errorMessage(error) {
    return `Technischer Fehler: ${error}`
  }

  const [pageState, setPageState] = useState({
    isLoading: true,
    accounts: [],
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Promise.all([Service.get()])
      .then(([accounts]) => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
          accounts: accounts,
        }))
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
          accounts: [],
        }))
      })
  }

  if (pageState.isLoading) {
    return <Loading />
  } else {
    return (
      <>
        <ComponentWrapper>
          <div className="text-center">
            <CreateButton
              onClick={() => navigate(`/${entityGroupName}/create`)}
              title={`${entityName} anlegen`}
            />
          </div>
        </ComponentWrapper>
        <ComponentWrapper>
          <Table>
            <TableHead>
              <TableHeaderRow>
                <TableCell>Account</TableCell>
                <TableCell></TableCell>
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {typeof pageState.accounts == 'undefined' ||
              pageState.accounts == null ||
              pageState.accounts?.length <= 0 ? (
                <TableRow index={-1}>
                  <TableCell colSpan={2} className="text-center">
                    {notFound}
                  </TableCell>
                </TableRow>
              ) : (
                pageState.accounts.map((item, index) => (
                  <TableRow key={item._id} index={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <ActionIconWrapper>
                        <OpenButton
                          onClick={() => navigate(`/${entityGroupName}/${item._id}/update`)}
                        />
                      </ActionIconWrapper>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </ComponentWrapper>
      </>
    )
  }
}

export default Index
