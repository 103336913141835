import authHeader from '../helpers/auth-header'
import http from '../helpers/http-common'

const initializeObject = () => {
  return {
    _id: '',
    createdBy: '',
    createdAt: '',
    lastChangedAt: '',
    lastChangedBy: '',

    name: '',
    config: {},
  }
}

async function getList() {
  let list = [{ _id: '', name: '-' }]
  const result = await this.get()
  return list.concat(result)
}

async function get(name) {
  return await http.get(`configs${name ? `?name=${name}` : ''}`, { headers: authHeader() })
}

async function getConfigObject(name) {
  return await http.get(`configs/object/${name}`, { headers: authHeader() })
}

async function getByObjectId(objectId) {
  return await http.get(`admin/configs/${objectId}`, { headers: authHeader() })
}

async function create(data) {
  return await http.post('admin/configs', data, { headers: authHeader() })
}

async function update(data) {
  return await http.put(`admin/configs/${data._id}`, data, { headers: authHeader() })
}

async function deleteByObjectId(objectId) {
  return await http.delete(`admin/configs/${objectId}`, { headers: authHeader() })
}

const service = {
  initializeObject,
  get,
  getList,
  create,
  getByObjectId,
  getConfigObject,
  update,
  deleteByObjectId,
}

export default service
