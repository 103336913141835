import authHeader from '../helpers/auth-header'
import http from '../helpers/http-common'

const initContact = () => {
  return {
    _id: '',
    createdBy: '',
    createdAt: '',
    lastChangedAt: '',
    lastChangedBy: '',

    customer: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    description: '',
  }
}

async function get(customerId) {
  let query = ''
  if (customerId) {
    query = `?customerId=${customerId}`
  }

  return await http.get(`admin/contacts${query}`, { headers: authHeader() })
}

async function create(contact) {
  return http.post(`admin/customers/${contact.customer}/contacts`, contact, {
    headers: authHeader(),
  })
}

async function getByObjectId(objectId) {
  return await http.get(`admin/contacts/${objectId}`, { headers: authHeader() })
}

async function update(data) {
  return await http.put(`admin/contacts/${data._id}`, data, { headers: authHeader() })
}

async function deleteByObjectId(contactId) {
  return await http.delete(`admin/contacts/${contactId}`, { headers: authHeader() })
}

const service = {
  initContact,
  get,
  create,
  getByObjectId,
  update,
  deleteByObjectId,
}

export default service
