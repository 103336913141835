import Loading from 'components/Common/Loading'
import { StatusButton } from 'components/Common/StatusButton'
import { Table, TableCell, TableHeaderCell, TableHeaderRow, TableRow } from 'components/Table/Table'
import { TableBody } from 'components/Table/TableBody'
import { TableHead } from 'components/Table/TableHead'
import { useEffect, useState } from 'react'
import ActionService from 'services/action.service'

export default function Dashboard() {
  const [pageState, setPageState] = useState({
    show: true,
    initialLoadFinished: false,
    isLoading: true,
    data: [],
  })

  const getDashboard = async () => {
    try {
      const result = await ActionService.getDashboard()
      setPageState({
        initialLoadFinished: true,
        isLoading: false,
        data: result,
        show: true,
      })
    } catch (error) {
      console.error('Error fetching calendar data:', error)
      setPageState({
        initialLoadFinished: true,
        isLoading: false,
        data: [],
        show: true,
      })
    }
  }

  useEffect(() => {
    if (!pageState.initialLoadFinished) {
      getDashboard()
    }
  }, [])

  const openInNewTab = (id) => {
    window.open(
      `${window.location.protocol}//${window.location.host}/actions/${id}/update`,
      '_blank'
    )
  }

  if (!pageState.initialLoadFinished) {
    return <Loading />
  }

  const renderActionTable = (title, actions) => (
    <Table className="text-xs max-w-lg">
      <TableHead>
        <TableHeaderRow>
          <TableHeaderCell>Liste</TableHeaderCell>
          <TableHeaderCell>Kunde</TableHeaderCell>
          <TableHeaderCell>Datum</TableHeaderCell>
          <TableHeaderCell>Typ</TableHeaderCell>
          <TableHeaderCell>
            <div className="flex items-center justify-between">Status</div>
          </TableHeaderCell>
        </TableHeaderRow>
      </TableHead>
      <TableBody>
        {pageState.show && actions && actions.length > 0 ? (
          actions.map((action, index) => (
            <TableRow key={action._id} index={index} onClick={() => openInNewTab(action._id)}>
              <TableCell className={'text-nowrap'}>
                {action.deal?.account.name ?? '-kein Deal-'}
              </TableCell>
              <TableCell className={'text-nowrap'}>
                {action.deal?.customer?.name ?? '-kein Kunde-'}
              </TableCell>
              <TableCell className={'text-nowrap'}>
                {action.date.replace('T00:00:00.000Z', '')}
              </TableCell>
              <TableCell className={'text-nowrap'}>{action.type}</TableCell>
              <TableCell className={'text-nowrap'}>
                <StatusButton status={action.status} />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={5}>Keine Versendungen mit Status {title}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )

  return (
    <div className="flex flex-row justify-center px-2 overflow-hidden ">
      {pageState.isLoading || pageState.data.length <= 0 ? (
        <Loading />
      ) : (
        <div className="flex flex-col md:flex-row gap-4 w-full justify-center">
          {renderActionTable(
            '"Werbemittel oder Werbemittel Optimierung"',
            pageState.data.actionsWerbemittel
          )}
          {renderActionTable('"Warte auf Werbemittel"', pageState.data.actionsWarteAufWerbemittel)}
        </div>
      )}
    </div>
  )
}
