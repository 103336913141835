import authHeader from '../helpers/auth-header'
import http from '../helpers/http-common'

async function get() {
  return await http.get('admin/info', { headers: authHeader() })
}

const InfoService = {
  get,
}

export default InfoService
