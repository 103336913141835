import { useContext, useState } from 'react'
import { UserContext } from '../App'
import { authenticationService } from '../services/authentication.service'

import { useToastContext } from '../context/ToastContext'

const Login = () => {
  const addToast = useToastContext()
  const { dispatch } = useContext(UserContext)
  const [login, setLogin] = useState({ username: '', password: '' })
  const { username, password } = login

  async function onHandleChange(event) {
    const { name, value } = event.target
    switch (name) {
      default:
        setLogin((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  async function signIn(e) {
    e.preventDefault()
    authenticationService.logout()
    await authenticationService
      .login({ username: username, password: password })
      .then((result) => {
        dispatch({ type: 'USER', payload: result })
      })
      .catch((error) => {
        addToast('Login fehlgeschlagen', 'error')
      })
  }

  return (
    <div className="container flex justify-center items-center h-screen">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md">
        <h1 className="text-2xl font-bold mb-4">Logical Leads App</h1>
        <form onSubmit={signIn} className="space-y-4">
          <div className="flex flex-col">
            <label htmlFor="username" className="text-sm mb-2">
              Username
            </label>
            <input
              id="username"
              name="username"
              type="text"
              value={username}
              onChange={onHandleChange}
              className="border rounded-md py-2 px-3 focus:outline-none"
              autoFocus
              autoComplete="off"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="password" className="text-sm mb-2">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={onHandleChange}
              className="border rounded-md py-2 px-3 focus:outline-none"
              autoComplete="off"
            />
          </div>
          <button
            type="submit"
            disabled={!username || !password}
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  )
}
export default Login
