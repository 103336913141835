import { CircleStackIcon, UserIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import InfoService from 'services/info.service'

export const InfoHeader = ({ userState }) => {
  const [pageState, setPageState] = useState({
    initialLoadFinished: false,
    apiInfo: null,
  })

  const { initialLoadFinished, apiInfo } = pageState

  const getApiInfo = async () => {
    const result = await InfoService.get()
    setPageState((prev) => ({ ...prev, apiInfo: result, initialLoadFinished: true }))
  }

  useEffect(() => {
    if (!initialLoadFinished && userState.isAdmin) {
      getApiInfo()
    }
  }, [initialLoadFinished])

  const headerBg = cx('h-6 w-6 rounded-lg', process.env.REACT_APP_ENVCOLOR)

  return (
    <div className="bg-white text-black font-bold text-xs px-6 py-3 flex items-center mb-2">
      <div className="flex items-center gap-1 flex-1">
        <UserIcon className={headerBg} />
        <span className="text-gray-800">
          {userState?.username} <br />
          {userState?.roles[0]}
        </span>
      </div>
      <div className="flex-1 text-center">
        <h1 className="font-bold uppercase text-xl">{process.env.REACT_APP_TITLE}</h1>
        <hr className="bg-red-400" />
      </div>
      <div className="flex items-center flex-1 justify-end gap-1">
        {apiInfo && userState.isAdmin && (
          <>
            <span className="text-right">
              {apiInfo.db.server}
              <br /> {apiInfo.db.database}
            </span>
            <CircleStackIcon className={headerBg} />
          </>
        )}
      </div>
    </div>
  )
}

export default InfoHeader
