import { CreateButton, DeleteButton, OpenButton } from 'components/Button/Buttons'
import {
  ActionIconWrapper,
  ComponentWrapper,
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table/Table'
import { TableBody } from 'components/Table/TableBody'
import { TableHead } from 'components/Table/TableHead'
import { useToastContext } from 'context/ToastContext'
import { formatDate } from 'helpers/date-helper'
import { getDataForPagination } from 'helpers/pagination'
import useLocalStorage from 'hooks/useLocalStorage'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AccountService from 'services/account.service'
import { getCustomerList } from 'services/customer.service'
import Service from 'services/deal.service'
import { DropDown } from '../Common/Inputs'
import Loading from '../Common/Loading'
import SimpleModal from '../Common/SimpleModal'
import Paging from '../Paging'

const Index = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const [modal, setModal] = useState({ show: false })

  const [pageState, setPageState] = useState({
    data: [],
    customers: [],
    accounts: [],
    totalResults: null,
  })

  const [selectedAccount, setSelectedAccount] = useLocalStorage('deals-selected-account', null)
  const [selectedCustomer, setSelectedCustomer] = useLocalStorage('deals-selected-customer', null)
  const [selectedPageSize, setSelectedPageSize] = useLocalStorage('deals-selected-page-size', 20)
  const [selectedPage, setSelectedPage] = useLocalStorage('deals-selected-page', 1)

  const entityGroupName = 'deals'
  const entityName = 'Kampagne'
  const notFound = `Keine Kampagne gefunden`

  function errorMessage(error) {
    return `Technischer Fehler: ${error}`
  }

  function deleteSuccessMessage(objectId) {
    return `Die Kampagne (ID: ${objectId}) wurde gelöscht.`
  }

  useEffect(() => {
    getData()
  }, [selectedAccount, selectedCustomer, selectedPageSize, selectedPage])

  const getData = async () => {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Promise.all([
      Service.getPaging(selectedCustomer, selectedAccount, selectedPageSize),
      Service.get(selectedCustomer, selectedAccount, selectedPageSize, selectedPage),
      AccountService.getList(),
      getCustomerList(),
    ])
      .then(([pagingResult, deals, accounts, customers]) => {
        if (pagingResult == null || pagingResult.length <= 0) {
          navigate(`/${entityGroupName}/`)
          addToast(
            'Es wurden keine Kampagnen gefunden angelegt. Es muss mindestens ein Kunde existieren.',
            'error'
          )
        }

        if (customers == null || customers.length <= 0) {
          navigate(`/${entityGroupName}/`)
          addToast(
            'Es sind keine Kunden angelegt. Es muss mindestens ein Kunde existieren.',
            'error'
          )
        }

        if (accounts == null || accounts.length <= 0) {
          navigate(`/${entityGroupName}/`)
          addToast(
            'Es sind keine Accounts angelegt. Es muss mindestens ein Account existieren.',
            'error'
          )
        }

        deals.forEach((deal) => {
          var firstAction = deal?.actions?.find((x) => (x.type = 'HV'))
          if (firstAction) {
            deal.mainAction = firstAction
          }
        })
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
          data: deals,
          customers: customers,
          accounts: accounts,
          totalResults: pagingResult.count,
        }))
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  async function handleDelete(dealId) {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Service.deleteByObjectId(dealId)
      .then((response) => {
        addToast(deleteSuccessMessage(dealId), 'success')
        setModal({ show: false })
        getData()
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
      })
      .finally(() => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  async function openDeleteModal(dealId, name) {
    setModal({
      show: true,
      title: `Lösche ${entityName}`,
      text: `Willst du "${name}" wirklich löschen?`,
      onCancelClicked: () => setModal({ show: false }),
      onOkayClicked: () => handleDelete(dealId),
      submitButton: 'Ja',
      backButton: 'Nein',
    })
  }

  if (pageState.isLoading) {
    return <Loading />
  } else {
    return (
      <>
        <ComponentWrapper>
          <div className="flex justify-center items-center">
            {pageState.customers && (
              <CreateButton
                onClick={() => navigate(`/${entityGroupName}/create`)}
                title={`${entityName} anlegen`}
              />
            )}
          </div>
        </ComponentWrapper>
        <ComponentWrapper>
          <div className="flex">
            <div className="basis-1/3">
              <DropDown
                list={pageState.customers}
                title="Filter Kunde"
                name="selectedCustomer"
                value={selectedCustomer}
                onChange={(event) => setSelectedCustomer(event.target.value)}
              />
            </div>
            <div className="basis-1/3">
              <DropDown
                list={pageState.accounts}
                title="Filter Account"
                name="selectedAccount"
                value={selectedAccount}
                onChange={(event) => setSelectedAccount(event.target.value)}
              />
            </div>
            <div className="basis-1/3">
              <DropDown
                list={[
                  { _id: '20', name: '20' },
                  { _id: '50', name: '50' },
                  { _id: '100', name: '100' },
                ]}
                title="Anzahl Ergebnisse"
                name="selectedPageSize"
                value={selectedPageSize}
                onChange={(event) => setSelectedPageSize(event.target.value)}
              />
            </div>
          </div>
          <Paging
            key={1}
            data={getDataForPagination(
              pageState.totalResults,
              selectedPageSize,
              selectedPage,
              setSelectedPage
            )}
          />
          <Table>
            <TableHead>
              <TableHeaderRow>
                <TableCell>Kunde</TableCell>
                <TableCell>Account</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Buchungsdatum</TableCell>
                <TableCell>1. HV</TableCell>
                <TableCell></TableCell>
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {typeof pageState.data == 'undefined' ||
              pageState.data == null ||
              pageState.data.length <= 0 ? (
                <tr>
                  <TableCell colSpan={4} className="text-center">
                    {notFound}
                  </TableCell>
                </tr>
              ) : (
                pageState.data.map((item, index) => (
                  <TableRow key={item._id} index={index}>
                    <TableCell>{item.customer ? item.customer.name : '- gelöscht -'}</TableCell>
                    <TableCell>{item.account.name}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell className="text-nowrap">{formatDate(item.bookingDate)}</TableCell>
                    <TableCell className="text-nowrap">
                      {item.mainAction ? (
                        <>
                          <a
                            href={`./actions/${item.mainAction._id}/update`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.mainAction.type} {formatDate(item.mainAction.date)}{' '}
                            {item.mainAction.time}
                          </a>
                        </>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>
                      {item.customer && (
                        <ActionIconWrapper>
                          <OpenButton
                            onClick={() => navigate(`/${entityGroupName}/${item._id}/update`)}
                          />
                          <DeleteButton onClick={() => openDeleteModal(item._id, item.name)} />
                        </ActionIconWrapper>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Paging
            key={2}
            data={getDataForPagination(
              pageState.totalResults,
              selectedPageSize,
              selectedPage,
              setSelectedPage
            )}
          />
        </ComponentWrapper>
        {modal.show && <SimpleModal modal={modal} />}
      </>
    )
  }
}

export default Index
