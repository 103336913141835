import { faClipboard, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table, TableCell, TableHeaderCell, TableHeaderRow, TableRow } from 'components/Table/Table'
import { TableBody } from 'components/Table/TableBody'
import { TableHead } from 'components/Table/TableHead'

export const FileTable = ({ files, onDeleteFile, title, showDeleteButton }) => {
  const handleCopy = (event) => {
    navigator.clipboard.writeText(event)
  }

  return (
    <Table>
      <TableHead>
        <TableHeaderRow>
          <TableHeaderCell colSpan={2}>{title}</TableHeaderCell>
        </TableHeaderRow>
      </TableHead>
      <TableBody>
        {files?.length > 0 ? (
          files.map((file, key) => (
            <TableRow key={key} index={key}>
              <TableCell>{file.name}</TableCell>
              <TableCell className="flex gap-2 justify-end">
                {showDeleteButton && (
                  <a
                    className="text-red-500 cursor-pointer"
                    title="Datei löschen"
                    onClick={() => onDeleteFile(key)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </a>
                )}
                <a
                  className=" cursor-pointer"
                  title="Datei herunterladen"
                  download={file.name}
                  href={file.base64}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </a>
                <span
                  className="cursor-pointer"
                  title="Dateiname in Zwischenablage"
                  onClick={() => handleCopy(file.name)}
                >
                  <FontAwesomeIcon icon={faClipboard} />
                </span>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow index={0}>
            <TableCell colSpan={2}>Keine Dateien</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
