import { AppContent } from 'components/AppContent'
import { createContext, useEffect, useReducer } from 'react'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import AuthVerify from './components/AuthVerify'
import Login from './components/Login'
import { ToastContextProvider } from './context/ToastContext'
import { initialUserState, userReducer } from './reducer/userReducer'
import { authenticationService } from './services/authentication.service'
export const UserContext = createContext()

const App = () => {
  const [userState, dispatch] = useReducer(userReducer, initialUserState)
  console.log('REACT_APP_API_ADDRESS: ' + process.env.REACT_APP_API_ADDRESS)
  console.log('REACT_APP_TITLE: ' + process.env.REACT_APP_TITLE)
  console.log('REACT_APP_ENVCOLOR: ' + process.env.REACT_APP_ENVCOLOR)

  useEffect(() => {
    const user = authenticationService.getCurrentUser()

    if (user) {
      dispatch({ type: 'USER', payload: user })
    }
  }, [])

  return (
    <UserContext.Provider value={{ userState, dispatch }}>
      <title>{process.env.REACT_APP_TITLE}</title>
      <ToastContextProvider>
        <Router>
          {!userState?.accessToken ? <Login /> : <AppContent userState={userState} />}
          <AuthVerify
            logOut={() => {
              authenticationService.logout()
              window.location.reload(true)
            }}
          />
        </Router>
      </ToastContextProvider>
    </UserContext.Provider>
  )
}
export default App
