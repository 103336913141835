import classNames from 'classnames'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

const ToastContext = createContext()

export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const addToast = useCallback(
    function (text, type) {
      setToasts((toasts) => [...toasts, { text: text, type: type }])
      setTimeout(() => setToasts((toasts) => toasts.slice(1)), 3000)
    },
    [setToasts]
  )

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className="fixed top-0 right-0 p-3 space-y-3">
        {toasts.map((item, index) => (
          <Toaster item={item} key={index} />
        ))}
      </div>
    </ToastContext.Provider>
  )
}

export function useToastContext() {
  return useContext(ToastContext)
}

const Toaster = ({ item }) => {
  const [show, setShow] = useState(true)
  const [bgColor, setColor] = useState('bg-blue-500')
  const [date, setDate] = useState('')
  const [header, setHeader] = useState('Info')

  useEffect(() => {
    setDate(new Date().toLocaleString())
    switch (item.type) {
      case 'success':
        setColor('bg-green-500')
        setHeader('Erfolg')
        break
      case 'error':
        setColor('bg-red-500')
        setHeader('Fehler')
        break
      case 'warning':
        setColor('bg-yellow-500')
        setHeader('Warnung')
        break
      case 'info':
        setColor('bg-blue-500')
        setHeader('Info')
        break
      default:
        setColor('bg-blue-500')
        setHeader('Info')
        break
    }
  }, [item.type])

  useEffect(() => {
    const timer = setTimeout(() => setShow(false), 3000)
    return () => clearTimeout(timer)
  }, [])

  const classsNames = classNames(
    'max-w-xs w-full text-md text-white p-4 rounded-lg shadow-lg transform transition-all duration-300 ease-in-out opacity-90 hover:opacity-100 hover:scale-105',
    bgColor
  )

  return (
    show && (
      <div className={classsNames}>
        <div className="flex justify-between items-center mb-2">
          <strong className="text-lg">{header}</strong>
          <small>{date}</small>
          <button
            onClick={() => setShow(false)}
            className="ml-2 text-white hover:text-gray-200 focus:outline-none"
          >
            &times;
          </button>
        </div>
        <div>{item.text}</div>
      </div>
    )
  )
}
