import DetailFooter from 'components/Common/DetailFooter'
import { InputPassword, InputText } from 'components/Common/Inputs'
import { useToastContext } from 'context/ToastContext'
import { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { useNavigate, useParams } from 'react-router-dom'
import Service from 'services/account.service'
import CommonService from 'services/common.service'
import MailSystemService from 'services/mailSystem.service'

const entityGroupName = `accounts`
const entityName = 'Account'

export const AccountForm = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const { objectId } = useParams()
  const [data, setData] = useState(Service.initializeObject)
  const [editMode, setEditMode] = useState(false)
  const [createMode] = useState(typeof objectId === 'undefined')

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    try {
      const mailSystems = await MailSystemService.get()

      if (mailSystems == null || mailSystems.length <= 0) {
        navigate(`/${entityGroupName}/`)
        addToast(
          'Es sind keine MailSysteme angelegt. Es muss mindestens ein MailSystem existieren.',
          'error'
        )
      }

      if (!createMode) {
        const currentAccount = await Service.getByObjectId(objectId)
        mailSystems.forEach((mailSystem) => {
          if (
            !currentAccount.credentials.find((x) => {
              return x.mailSystem._id === mailSystem._id
            })
          ) {
            currentAccount.credentials.push({
              mailSystem: mailSystem,
              username: '',
              password: '',
            })
          }
        })

        setData(currentAccount)
      } else {
        var credentials = []

        mailSystems.forEach((mailSystem) => {
          if (
            !data.credentials.find((x) => {
              return x.mailSystem._id === mailSystem._id
            })
          ) {
            credentials.push({ mailSystem: mailSystem, username: '', password: '' })
          }
        })

        if (data.credentials !== null) {
          credentials = data.credentials.concat(credentials)
        }

        setData((prev) => ({ ...prev, credentials: credentials }))
      }
    } catch (error) {
      addToast(CommonService.errorMessage(error), 'error')
    }
  }

  const onChange = (event) => {
    CommonService.onHandleChange(setData, event)
  }

  async function handleOnSubmit() {
    if (createMode) {
      await Service.create(data)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, createMode), 'success')
          navigate(`/${entityGroupName}/${result._id}/update`)
        })
        .catch((error) => {
          addToast(CommonService.errorMessage(error), 'error')
        })
        .finally(() => {})
    } else {
      await Service.update(data)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, createMode), 'success')
          setEditMode(false)
        })
        .catch((error) => {
          addToast(CommonService.errorMessage(error), 'error')
        })
        .finally(() => {
          getData()
        })
    }
  }

  const handleCredentialChange = (index) => (event) => {
    const { name, value } = event.target

    // 1. Make a shallow copy of the items
    let credentials = [...data.credentials]

    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...credentials[index] }

    // 3. Replace the property you're intested in
    item[name] = value

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    credentials[index] = item

    setData((prev) => ({ ...prev, credentials: credentials }))
  }

  const handleSecondaryColorChange = (color) => {
    setData((prev) => ({ ...prev, colorSecondary: color.hex }))
  }

  const handlePrimaryColorChange = (color) => {
    setData((prev) => ({ ...prev, colorPrimary: color.hex }))
  }

  return (
    <>
      <div className="flex gap-2">
        <div className="basis-1/3">
          <InputText
            name="name"
            title="Name"
            value={data.name}
            onChange={onChange}
            required={true}
            disabled={!editMode && !createMode}
          />
          {data.credentials?.length > 0 ? (
            data.credentials.map((credential, index) => (
              <div key={credential._id}>
                <div>
                  <InputText
                    key={index + '_mailSystem'}
                    name="mailSystem"
                    title="Mail System Name"
                    value={credential.mailSystem.name}
                    onChange={handleCredentialChange(index)}
                    disabled={true}
                  />
                </div>
                <div>
                  <InputText
                    key={index + '_username'}
                    name="username"
                    title="Username"
                    value={credential.username}
                    onChange={handleCredentialChange(index)}
                    disabled={!editMode && !createMode}
                  />
                </div>
                <div>
                  <InputPassword
                    key={index + '_password'}
                    name="password"
                    title="Passwort"
                    value={credential.password}
                    onChange={handleCredentialChange(index)}
                    disabled={!editMode && !createMode}
                  />
                </div>
              </div>
            ))
          ) : (
            <p>nix</p>
          )}
        </div>
        <div className="basis-1/3">
          <p>Farbe Wochenende</p>
          <SketchPicker color={data.colorSecondary} onChange={handleSecondaryColorChange} />
        </div>
        <div className="basis-1/3">
          <p>Farbe Werktag</p>
          <SketchPicker color={data.colorPrimary} onChange={handlePrimaryColorChange} />
        </div>
      </div>
      <DetailFooter
        data={data}
        showSaveButton={editMode || createMode}
        saveButtonDisabled={!data.name}
        showDatabaseInfo={true}
        handleOnSubmit={handleOnSubmit}
        handleOnEditClicked={() => setEditMode(true)}
      />
    </>
  )
}
