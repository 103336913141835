import Loading from 'components/Common/Loading'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CommonService from 'services/common.service'
import {
  createCustomer,
  getCustomer,
  initCustomer,
  updateCustomer,
} from 'services/customer.service'
import { useToastContext } from '../../context/ToastContext'
import DetailFooter from '../Common/DetailFooter'
import { DropDown, InputEmail, InputPhone, InputText, InputTextArea } from '../Common/Inputs'

const entityGroupName = 'customers'
const entityName = 'Kunde'

export const CustomerForm = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const { objectId } = useParams()

  const [pageState, setPageState] = useState({
    customer: initCustomer(),
    contactList: [],
    editMode: false,
    createMode: typeof objectId === 'undefined',
  })

  const { customer, editMode, createMode } = pageState

  const readOnly = !editMode && !createMode
  const saveButtonDisabled = !customer.name

  useEffect(() => {
    const getData = async () => {
      if (!createMode) {
        try {
          const result = await getCustomer(objectId)
          let tempContactList = [{ _id: '', name: 'Kein Hauptkontakt' }]
          if (result.contacts) {
            result.contacts.forEach((contact) => {
              tempContactList.push({
                _id: contact._id,
                name: `${contact.firstName} ${contact.lastName}`,
              })
            })
          }

          setPageState((prevState) => ({
            ...prevState,
            contactList: tempContactList,
            customer: result,
          }))
        } catch (error) {
          addToast(CommonService.errorMessage(error), 'error')
        }
      }
    }

    getData()
  }, [objectId, editMode])

  const onChange = (event) => {
    const { name, value } = event.target
    setPageState((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        [name]: value,
      },
    }))
  }

  const handleOnSubmit = async () => {
    try {
      if (createMode) {
        const result = await createCustomer(customer)
        addToast(CommonService.successMessage(entityName, createMode), 'success')
        navigate(`/${entityGroupName}/${result._id}/update`)
      } else {
        await updateCustomer(customer)
        addToast(CommonService.successMessage(entityName, createMode), 'success')
        setPageState((prevState) => ({ ...prevState, editMode: false }))
      }
    } catch (error) {
      addToast(CommonService.errorMessage(error), 'error')
    }
  }

  if (!customer) {
    return <Loading />
  }

  return (
    <section>
      <div className="flex gap-2">
        <div className="flex-1">
          <InputText
            name="name"
            title="Name"
            value={customer.name}
            onChange={onChange}
            required={true}
            disabled={readOnly}
          />
          <InputText
            name="customerNumber"
            title="Kundennummer"
            value={customer.customerNumber}
            onChange={onChange}
            required={true}
            disabled={readOnly}
          />
          <InputPhone
            name="phone"
            title="Telefon"
            value={customer.phone}
            onChange={onChange}
            disabled={readOnly}
          />
          <InputText
            name="website"
            title="Webseite"
            value={customer.website}
            onChange={onChange}
            disabled={readOnly}
          />
          <InputEmail
            name="email"
            title="Email"
            value={customer.email}
            onChange={onChange}
            disabled={readOnly}
          />
          <InputText
            name="taxNumber"
            title="Steuernummer"
            value={customer.taxNumber}
            onChange={onChange}
            disabled={readOnly}
          />
        </div>
        <div className="flex-1">
          <InputText
            name="street"
            title="Straße"
            value={customer.street}
            onChange={onChange}
            disabled={readOnly}
          />
          <InputText
            name="streetNumber"
            title="Hausnummer"
            value={customer.streetNumber}
            onChange={onChange}
            disabled={readOnly}
          />
          <InputText
            name="city"
            title="Ort"
            value={customer.city}
            onChange={onChange}
            disabled={readOnly}
          />
          <InputText
            name="zipCode"
            title="Postleitzahl"
            value={customer.zipCode}
            onChange={onChange}
            disabled={readOnly}
          />
          <InputText
            name="state"
            title="Bundesland"
            value={customer.state}
            onChange={onChange}
            disabled={readOnly}
          />
          <InputText
            name="country"
            title="Land"
            value={customer.country}
            onChange={onChange}
            disabled={readOnly}
          />
        </div>
      </div>
      <div className="flex gap-2">
        <div>
          {!createMode && (
            <DropDown
              name="mainContact"
              title="Hauptkontakt"
              value={customer.mainContact}
              onChange={onChange}
              list={pageState.contactList}
              disabled={readOnly}
            />
          )}
        </div>
        <div>
          <InputTextArea
            name="testEmailAddresses"
            title="Testmail Empfänger"
            value={customer.testEmailAddresses}
            onChange={onChange}
            disabled={readOnly}
          />
        </div>
      </div>
      <DetailFooter
        data={customer}
        showSaveButton={!readOnly}
        saveButtonDisabled={saveButtonDisabled}
        showDatabaseInfo={!createMode}
        handleOnSubmit={handleOnSubmit}
        handleOnEditClicked={() => setPageState((prevState) => ({ ...prevState, editMode: true }))}
        handleOnCancelClicked={() =>
          setPageState((prevState) => ({ ...prevState, editMode: false }))
        }
      />
    </section>
  )
}

export default CustomerForm
