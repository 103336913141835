import {
  ActionIconWrapper,
  ComponentWrapper,
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table/Table'
import { TableHead } from 'components/Table/TableHead'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ConfigService from 'services/config.service'
import { CreateButton, OpenButton } from '../../components/Button/Buttons'
import { useToastContext } from '../../context/ToastContext'
import Loading from '../Common/Loading'

const Index = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()

  const entityGroupName = 'configs'
  const entityName = 'Configs'
  const notFound = `Keine Configs gefunden`

  function errorMessage(error) {
    return `Technischer Fehler: ${error}`
  }

  const [pageState, setPageState] = useState({
    isLoading: true,
    configs: [],
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Promise.all([ConfigService.get()])
      .then(([configs]) => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
          configs: configs,
        }))
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
          configs: [],
        }))
      })
  }

  if (pageState.isLoading) {
    return <Loading />
  } else {
    return (
      <>
        <ComponentWrapper>
          <div className="text-center">
            <CreateButton
              onClick={() => navigate(`/${entityGroupName}/create`)}
              title={`${entityName} anlegen`}
            />
          </div>
        </ComponentWrapper>
        <ComponentWrapper>
          <Table>
            <TableHead>
              <TableHeaderRow>
                <TableCell>Name</TableCell>
                <TableCell></TableCell>
              </TableHeaderRow>
            </TableHead>
            <tbody>
              {typeof pageState.configs == 'undefined' ||
              pageState.configs == null ||
              pageState.configs?.length <= 0 ? (
                <TableRow index={0}>
                  <TableCell colSpan={6} className="text-center">
                    {notFound}
                  </TableCell>
                </TableRow>
              ) : (
                pageState.configs.map((item, index) => (
                  <TableRow key={item._id} index={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <ActionIconWrapper>
                        <OpenButton
                          onClick={() => navigate(`/${entityGroupName}/${item._id}/update`)}
                        />
                      </ActionIconWrapper>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </tbody>
          </Table>
        </ComponentWrapper>
      </>
    )
  }
}

export default Index
