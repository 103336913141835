import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'

const Paging = ({ data }) => {
  const { selectedPage, pages, setPageCallback, pageCount } = data
  return (
    <div className="flex justify-center items-center p-2">
      <div>
        <nav className="inline-flex shadow-sm -space-x-px" aria-label="Pagination">
          <button
            onClick={() => setPageCallback(1)}
            disabled={!(selectedPage > 1)}
            className={`items-center px-2 py-2 rounded-l-md border border-gray-300 text-gray-500 hover:bg-gray-400 ${!(selectedPage > 1) && 'cursor-not-allowed'}`}
          >
            <ChevronDoubleLeftIcon className="h-5 w-5" />
          </button>
          <button
            onClick={() => setPageCallback(selectedPage - 1)}
            disabled={!(selectedPage > 1)}
            className={` items-center px-2 py-2 border border-gray-300 text-gray-500 hover:bg-gray-400 ${!(selectedPage > 1) && 'cursor-not-allowed'}`}
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          {/* Page Numbers */}
          {pages &&
            pages.map((page) => (
              <button
                key={page}
                onClick={() => setPageCallback(page)}
                className={`items-center px-3 py-2 border border-gray-300 text-gray-700 hover:bg-gray-400 ${page === selectedPage && 'bg-gray-300'}`}
              >
                {page}
              </button>
            ))}
          <button
            onClick={() => setPageCallback(selectedPage + 1)}
            disabled={!(selectedPage < pageCount)}
            className={` items-center px-2 py-2 border border-gray-300   text-gray-500 hover:bg-gray-400 ${!(selectedPage < pageCount) ? 'cursor-not-allowed' : ''}`}
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>
          <button
            onClick={() => setPageCallback(pageCount)}
            disabled={!(selectedPage < pageCount)}
            className={`items-center px-2 py-2 rounded-r-md border border-gray-300 text-gray-500 hover:bg-gray-400 ${!(selectedPage < pageCount) ? 'cursor-not-allowed' : ''}`}
          >
            <ChevronDoubleRightIcon className="h-5 w-5" />
          </button>
        </nav>
      </div>
    </div>
  )
}

export default Paging
